import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateQuestionComponent } from '../questions/components/translate-question/translate-question.component';
import { CreateDocumentTypeComponent } from './components/create-document-type/create-document-type.component';
import { DocumentsTypesComponent } from './components/documents-types/documents-types.component';
import { EditDocumentTypeComponent } from './components/edit-document-type/edit-document-type.component';

const routes: Routes = [
  {
    path: 'documents-types', children: [
      { path: '', component: DocumentsTypesComponent },
      { path: 'create-document-type', component: CreateDocumentTypeComponent },
      { path: 'edit-document-type/:id', component: EditDocumentTypeComponent }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
