import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentsService } from 'src/app/modules/documents/services/documents.service';
import { LocalizationService } from 'src/app/modules/localizations/services/localization.service';
import { TranslateQuestionGroupComponent } from 'src/app/modules/questions/components/translate-question-group/translate-question-group.component';
import { CountriesService } from '../../services/countries.service';

@Component({
  selector: 'app-translate-countries',
  templateUrl: './translate-countries.component.html',
  styleUrls: ['./translate-countries.component.scss']
})
export class TranslateCountriesComponent implements OnInit {

  countryTranslateForm: FormGroup;
  translateName: any;
  languageList: any = [];
  languageCode: any = [];

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private localizationService: LocalizationService,
    private countriesService: CountriesService,
    private dialogRef: MatDialogRef<TranslateCountriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    this.languageList = [];

    this.countryTranslateForm = this.fb.group({
      name: ['']
    });

    this.localizationService.getLanguages().subscribe((res: any) => {

      res.data.forEach((e) => {
        this.languageList.push({
          code: e.code,
          country: e.country,
          id: e.id,
          is_Default: e.is_Default,
          name: e.name
        });
      });

    });
  }

  tabchange(event) {
    console.log('Tabchange events', event);

    this.languageCode = [];
    this.languageList.forEach((e) => {
      if(e.name === event.tab.textLabel) {
        this.languageCode.push(e.code);
      }
    });
    
    if(this.languageCode.length !== 0) {
      this.getCountryTranslate(this.languageCode);
    }
  }

  getCountryTranslate(languageCode) {

    this.countriesService.getCountryTranslate(this.data, languageCode).subscribe((res) => {
      console.log('getQuestionGroupTranslate data', res);

      this.countryTranslateForm.setValue({
        name: res.data.name
      });
    });
  }

  get translateFormData() {
    return this.countryTranslateForm.controls;
  }

  countryTranslateSubmit() {

    this.translateName = [];
    this.languageList = [];

    this.translateName.push({
      name: this.translateFormData.name.value
    });

    this.countriesService.updateCountryTranslate(this.data, this.languageCode, this.translateName[0]).subscribe((res) => {
      console.log('Group translate data', res);
      this.openSnackbarSuccessMessage('Translate submitted successfully');
      this.ngOnInit();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
