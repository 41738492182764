<div class="container">
    <h1 class="header">Document Types</h1>
    <p class="p-text">
        Document types section description
    </p>
    <div class="row main-table">
        <div class="col-md-12">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                fxLayoutGap="1rem">
                <div>
                    <h2 class="table-subheader">Available Document Types</h2>
                </div>
                <div>
                    <div style="padding:10px;cursor:pointer;" (click)="createDocumentType()">
                        <img src="assets/images/add-icon.svg" alt="Add Icon" length="25" width="25" class="ml-1">
                        <span class="btn-text pl-2">Create document type</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <table mat-table [dataSource]="documentTypesData" matSort 
                            class="mat-elevation-z1" matSortActive="id" matSortDirection="asc">

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace"> # </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="key">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Key </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.documentTypeText}} </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Name </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.documentTypeName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="trips addition">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Trips Addition </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.tripsAddition | titlecase}} </td>
                            </ng-container>

                            <ng-container matColumnDef="service">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Service </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngFor="let service of element.services">
                                        {{service.name}} 
                                    </ng-container>
                                </td>
                            </ng-container>                           
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="headletterspace"> Actions</span>
                                </th>
                                <td mat-cell *matCellDef="let element" class="btn-group">
                                    <button type="button" class="action-buttons-edit" [routerLink]="['/documents/documents-types/edit-document-type', element.id]">Edit</button>
                                    <button type="button" class="action-buttons-translate" (click)="translateQuestion(element.id)">Translate</button>
                                    <button type="button" class="action-buttons-visibility" (click)="statusChange(element.id, $event.target.innerHTML)">{{element.isEnabled === true ? 'Enabled' : 'Disabled'}}</button>
                                    <button type="button" class="action-buttons-edit" (click)="removeDocumentType(element.id)">Delete</button>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="documentsTypesColumn; sticky: true"></tr>
                            <tr mat-row *matRowDef="let element; columns: documentsTypesColumn;" class="assessmentrow">
                            </tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>                    
                </div>
            </div>
        </div>
    </div>
</div>