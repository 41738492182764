import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  getAllCountries(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Countries`);
  }

  getAllA1HomeCountries(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Countries?CountryType=home&Registration=a1`);
  }

  getAllPwdHostCountries(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Countries?CountryType=host&Registration=pwd`);
  }

  enableHomeCountry(countryCode: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Countries/${countryCode}/home`, data);
  }

  disableHomeCountry(countryCode: any, data: any): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Countries/${countryCode}/home`, data);
  }

  enableHostCountry(countryCode: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Countries/${countryCode}/host`, data);
  }

  disableHostCountry(countryCode: any, data: any): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Countries/${countryCode}/host`, data);
  }

  enableA1Service(countryCode: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Countries/${countryCode}/a1`, data);
  }

  disableA1Service(countryCode: any, data: any): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Countries/${countryCode}/a1`, data);
  }

  enablePwdService(countryCode: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Countries/${countryCode}/pwd`, data);
  }

  disablePwdService(countryCode: any, data: any): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Countries/${countryCode}/pwd`, data);
  }

  getCountryTranslate(countryCode: number, languageCode: string): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/CountryTranslation/${countryCode}/translation/${languageCode}`);
  }

  updateCountryTranslate(countryCode: number, languageCode: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/CountryTranslation/${countryCode}/translation/${languageCode}`, data);
  }

}
