import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppService } from './core/services/app.service';
import { ConfigService } from './core/services/config.service';
import { EnvironmentService } from './core/services/environment.service';
import { ROLES } from './shared/constants/constants';
import { SharedService } from './shared/services/shared.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    public app: AppService,
    public sharedService: SharedService,
    private config: ConfigService,
    private router: Router,
    private env: EnvironmentService
  ) {  }
  
  ngOnInit() {
    this.app.bootstrap();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      const element = document.querySelector('#ContentArea');
      element.scrollIntoView();
    });

  }

}
