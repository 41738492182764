import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CountriesService } from '../../services/countries.service';
import { TranslateCountriesComponent } from '../translate-countries/translate-countries.component';


export interface Countries {
  id: number;
  code: string;
  name: string;
  is_Home: boolean;
  is_Host: boolean;
  is_A1: boolean;
  is_PWD: boolean;
}

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss'],
})
export class CountriesComponent implements OnInit {
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = ['One', 'Two', 'Three'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  homeFilter = ['Not a home', 'Is home'];
  hostFilter = ['Not a host', 'Is host'];
  servicesFilter = ['Is enabled', 'Is disabled'];

  displayedColumns: string[] = ['id', 'name', 'code', 'actions'];
  allCountries: any = [];
  filteredCountriesData: any = [];
  filteredCountriesArray : any = [];

  homeFilterControl = new FormControl('');
  hostFilterControl = new FormControl('');
  a1FilterControl = new FormControl('');
  pwdFilterControl = new FormControl('');

  filterValues = {
    name:'',
    is_Home: '',
    is_Host: '',
    is_A1: '',
    is_PWD: ''
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private countriesService: CountriesService  
  ) {}

  ngOnInit() {
    this.allCountries = [];
    this.getCountries();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    this.myControl.valueChanges
      .subscribe(
        name => {
          if (name !== null) {
            this.filterValues.name = name;
           // this.allCountries.filter = JSON.stringify(this.filterValues);
          }
        }
      );

    this.homeFilterControl.valueChanges
      .subscribe( 
        home => {
          if (home !== null) {
            if(home === 'Not a home'){
              this.filterValues.is_Home = 'false';
            }
            else if(home === 'Is home'){
              this.filterValues.is_Home = 'true';
            }
            else{
              this.filterValues.is_Home = '';
            }
            //this.allCountries.filter = JSON.stringify(this.filterValues);
          }
        }
      );

      this.hostFilterControl.valueChanges
      .subscribe(
        host => {
          if (host !== null) {
            if(host === 'Not a host'){
              this.filterValues.is_Host = 'false';
            }
            else if(host === 'Is host'){
              this.filterValues.is_Host = 'true';
            }
            else{
              this.filterValues.is_Host = '';
            }
            //this.allCountries.filter = JSON.stringify(this.filterValues);
          }
        }
      );

      this.a1FilterControl.valueChanges
      .subscribe(
        a1 => {
          if (a1 !== null) {
            if(a1 === 'Is enabled'){
              this.filterValues.is_A1 = 'true';
            }
            else if(a1 === 'Is disabled'){
              this.filterValues.is_A1 = 'false';
            }
            else{
              this.filterValues.is_A1 = '';
            }
            //this.allCountries.filter = JSON.stringify(this.filterValues);
          }
        }
      );

      this.pwdFilterControl.valueChanges
      .subscribe(
        pwd => {
          if (pwd !== null) {
            if(pwd === 'Is enabled'){
              this.filterValues.is_PWD = 'true';
            }
            else if(pwd === 'Is disabled'){
              this.filterValues.is_PWD = 'false';
            }
            else{
              this.filterValues.is_PWD = '';
            }
           // this.allCountries.filter = JSON.stringify(this.filterValues);
          }
        }
      );
  }

  getCountries() {
    try {
      this.countriesService.getAllCountries().subscribe((res) => {
        this.filteredCountriesData = res.data;
        this.allCountries = new MatTableDataSource<any>(res.data);
        this.allCountries.paginator = this.paginator;
        this.allCountries.sort = this.sort;
       
      });
    } catch (ex) {}
  }

  filterCountry() {
    this.filteredCountriesArray = [];
    this.filteredCountriesData.filter((data: any, filter: string): boolean => {
      if (this.allCountries.paginator) {
        this.allCountries.paginator.firstPage();
      }
      let values = JSON.stringify(this.filterValues)
      let filterValue = JSON.parse(values);
      let conditions = true;

      for (let filterKey in filterValue) {
        if (filterKey === 'name' && filterValue[filterKey] != '') {
          conditions = conditions && data[filterKey].trim().toLowerCase().indexOf(filterValue[filterKey]) !== -1;
        }
        else if(filterValue[filterKey] != ''){
          let boolValue = filterValue[filterKey] != '' ? JSON.parse(filterValue[filterKey]): false;
         filterValue[filterKey] = boolValue;
         conditions = conditions && filterValue[filterKey] === data[filterKey] ? true : false;
        }
      }
      if(conditions){
        this.filteredCountriesArray.push(data);
      }
      return conditions;
    });

    this.allCountries = new MatTableDataSource<any>(this.filteredCountriesArray);
    this.allCountries.paginator = this.paginator;
    this.allCountries.sort = this.sort;
  }

  translateQuestion(countryID) {
    const dialogRef = this.dialog.open(TranslateCountriesComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: countryID
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Home country API integration
   * @param code 
   * @param data 
   */

  enableHomeCountry(code: any, data: any) {
    this.countriesService.enableHomeCountry(code, data).subscribe((res) => {
      console.log('enable home country', res);
      this.getCountries();
    });
  }

  disableHomecountry(code: any, data: any) {
    this.countriesService.disableHomeCountry(code, data).subscribe((res) => {
      console.log('disable home country', res);
      this.getCountries();
    });
  }

  /**
   * Host country API intergration
   * @param code 
   * @param data 
   */

  enableHostCountry(code: any, data: any) {
    this.countriesService.enableHostCountry(code, data).subscribe((res) => {
      console.log('enable host country', res);
      this.getCountries();
    });
  }

  disableHostcountry(code: any, data: any) {
    this.countriesService.disableHostCountry(code, data).subscribe((res) => {
      console.log('disable host country', res);
      this.getCountries();
    });
  }

  /**
   * A1 service API integration
   * @param code 
   * @param data 
   */

  enableA1Service(code: any, data: any) {
    this.countriesService.enableA1Service(code, data).subscribe((res) => {
      console.log('enable host country', res);
      this.getCountries();
    });
  }

  disableA1Service(code: any, data: any) {
    this.countriesService.disableA1Service(code, data).subscribe((res) => {
      console.log('disable host country', res);
      this.getCountries();
    });
  }

  /**
   * PWD service API intergration
   * @param code 
   * @param data 
   */

  enablePwdService(code: any, data: any) {
    this.countriesService.enablePwdService(code, data).subscribe((res) => {
      console.log('enable host country', res);
      this.getCountries();
    });
  }

  disablePwdService(code: any, data: any) {
    this.countriesService.disablePwdService(code, data).subscribe((res) => {
      console.log('disable host country', res);
      this.getCountries();
    });
  }

  clearFilter(event) {
    this.myControl.reset();
    this.homeFilterControl.reset();
    this.hostFilterControl.reset();
    this.a1FilterControl.reset();
    this.pwdFilterControl.reset();
    this.allCountries.filter = '';
    this.filterValues = {
      name:'',
      is_Home: '',
      is_Host: '',
      is_A1: '',
      is_PWD: ''
    };
    this.allCountries = new MatTableDataSource<any>(this.filteredCountriesData);
    this.allCountries.paginator = this.paginator;
    this.allCountries.sort = this.sort;
    event.stopPropagation();
  }

  homeStatusChange(code: string, value: boolean, data: any) {
    if(!value){
      this.enableHomeCountry(code, data);
    }
    else
    {
      this.disableHomecountry(code, data);
    }
  }

  hostStatusChange(code: string, value: boolean, data: any) {
    if(!value){
      this.enableHostCountry(code, data);
    }
    else{
      this.disableHostcountry(code, data);
    }
  }

  a1StatusChange(code: string, value: boolean, data: any) {
    if(!value){
      this.enableA1Service(code, data);
    }
    else{
      this.disableA1Service(code, data);
    }
  }

  pwdStatusChange(code: string, value: boolean, data: any) {
    if(!value){
      this.enablePwdService(code, data);
    }
    else{
      this.disablePwdService(code, data);
    }
  }


  // TODO: Country translate

}
