import { ThisReceiver } from '@angular/compiler';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Observable } from 'rxjs';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit {

  languageList: any;
  languageListByCode: any;
  langDatasourceCopy: any;
  languageCode: any;
  selectedTab = 0;
  selected: any;
  langLength: number;
  myControl = new FormControl();
  translateForm: FormGroup;

  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent>


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  obs: Observable<any>;

  constructor(
    private localizationService: LocalizationService,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {

    this.languageList = [];
    this.languageListByCode = [];
    this.languageCode = [];
    this.langDatasourceCopy = [];

    this.translateForm = this.fb.group({
      id:[''],
      key: [''],
      value:['']
    });

    this.localizationService.getLanguages().subscribe((res: any) => {

      res.data.forEach((e) => {
        this.languageList.push({
          code: e.code,
          country: e.country,
          id: e.id,
          is_Default: e.is_Default,
          name: e.name
        });
      });

      console.log('Get all languages', this.languageList);

      res.data.forEach((e) => {
        this.localizationService.getTranslationByCode(e.code).subscribe((res: any) => {

          res.data.forEach((e) => {
            this.languageListByCode.push({
              id: e.id,
              key: e.key,
              value: e.value
            });            
          });
          console.log('languageListByCode ', this.languageListByCode);

          this.langDatasourceCopy = new MatTableDataSource<any>(this.languageListByCode);

          this.cdRef.detectChanges();
          this.langDatasourceCopy.paginator = this.paginator;
          this.obs = this.langDatasourceCopy.connect();

          this.langLength = this.langDatasourceCopy.connect().value.length;
          console.log('obs ', this.langDatasourceCopy.connect().value.length);
          this.langDatasourceCopy.filterPredicate = (data, filter) => {
            let languageData = { ...data };
            if (filter) {
              filter = filter.toLowerCase();
            }
            if (languageData.key) {
              languageData.key = languageData.key.toLowerCase();
            }
            return languageData.key.indexOf(filter) != -1;
          };

        });
      });

    });



  }

  filterTranslations(){
    if (this.myControl.value !== null && this.languageList) {
      this.langDatasourceCopy.filter = this.myControl.value.trim();
    }
  }

  getLanguageDataByCode(lang) {
    console.log('languageData', lang);

    this.languageListByCode = [];

    this.localizationService.getTranslationByCode(lang.code).subscribe((res: any) => {
    
      res.data.forEach((e) => {
        this.languageListByCode.push({
          id: e.id,
          key: e.key,
          value: e.value
        });
      });
      console.log('languageListByCode ', this.languageListByCode);

      this.langDatasourceCopy = new MatTableDataSource<any>(this.languageListByCode);
      this.cdRef.detectChanges();
      this.langDatasourceCopy.paginator = this.paginator;
      this.obs = this.langDatasourceCopy.connect();
      res.data.forEach(e => {
        this.translateForm.setValue({ 'id': e.id,
          'key': e.key,
          'value': e.value});
      });

      this.langLength = this.langDatasourceCopy.connect().value.length;

      console.log('obs ', this.langDatasourceCopy.connect().value.length);

    });
  }

  get translationData() {
    return this.translateForm.controls;
  }

  onTranslationSubmit() {
    console.log('translationData', this.translationData);
  } 

  clearFilter(event) {
    this.myControl.reset();
    this.langDatasourceCopy.filter = '';
    event.stopPropagation();
  }

}
