import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CountriesService } from 'src/app/modules/countries/services/countries.service';
import { QuestionCountryService } from '../../../services/question-country.service';
import { QuestionOptionService } from '../../../services/question-option.service';
import { SelectCountriesComponent } from '../select-countries/select-countries.component';

@Component({
  selector: 'app-add-question-countries',
  templateUrl: './add-question-countries.component.html',
  styleUrls: ['./add-question-countries.component.scss']
})
export class AddQuestionCountriesComponent implements OnInit {

  listCountries: MatTableDataSource<any> = null;
  countryID: any = [];
  removeCountryID: any = [];
  removeID: any;
  questionCountryBlacklistData: any = [];
  listCountriesData: any = [];
  showAddButton: boolean;
  showRemoveButton: boolean;
  filteredCountryData: any = [];
  newFilteredCountryData: any = [];

  countryForm: FormGroup;

  countriesColumn: string[] = ['id', 'name', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private countriesService: CountriesService,
    private questionCountryService: QuestionCountryService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SelectCountriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.countryForm = new FormGroup({
      search: new FormControl(null)
    });
  }

  ngOnInit(): void {

    this.questionCountryService.getQuestionCountryBlacklist(this.data.id).subscribe((res) => {
      console.log('Add question country blacklist data', res.data);
      this.questionCountryBlacklistData = res.data;
    });

    this.countriesService.getAllCountries().subscribe((res) => {
      console.log('List of host countries', res.data);
      this.listCountriesData = res.data;
      this.newFilteredCountryData.push(this.listCountriesData);

      this.listCountries = new MatTableDataSource<any>(res.data);
      this.listCountries.paginator = this.paginator;
      this.listCountries.sort = this.sort;

      this.listCountries.filterPredicate = (data, filter) => {
        let countryData = { ...data };
        if (filter) {
          filter = filter.toLowerCase();
        }
        if (countryData.name) {
          countryData.name = countryData.name.toLowerCase();
        }
        return countryData.name.indexOf(filter) != -1;
      };

    });

    this.questionCountryService.subjectCountryData.subscribe((res) => {
      console.log('After subject subscribed', res);
      this.filteredCountryData = res;

      this.newFilteredCountryData[0].forEach((e) => {
        res.forEach((data) => {
          if (e.id === data.id) {
            console.log('list of filtered ids', e.id);
            this.showAddButton = false;
            this.showRemoveButton = true;
          } else {
            this.showAddButton = true;
            this.showRemoveButton = false;
          }
        });
      });
    });

  }

  filterCountry() {
    if (this.countryForm.value.search !== null && this.listCountries) {
      this.listCountries.filter = this.countryForm.value.search.trim();
    }
  }

  clearFilter() {
    this.countryForm.patchValue({ search: '' });
    this.filterCountry();
  }

  addCountry(id: number) {
    this.countryID.push({
      "ids": [
        {
          "id": id
        }
      ]
    });
    this.questionCountryService.updateQuestionCountryBlacklist(this.data.id, this.countryID[0]).subscribe((res) => {
      console.log('updated data', res);
      this.openSnackbarSuccessMessage('Country added successfully');
    });
  }

  removeCountry(id: number) {
    this.removeCountryID.push({
      "ids": [
        {
          "id": id
        }
      ]
    });

    this.questionCountryService.removeQuestionCountryBlacklist(this.data.id, this.removeCountryID[0]).subscribe((res) => {
      console.log('Removed data', res);
      this.openSnackbarSuccessMessage('Country removed successfully');
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
