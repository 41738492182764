<div class="container">
    <h1 class="header">Translations</h1>
    <p class="p-text">
        This section allows you to translate the NIUMAD traveler interface to available languages. You can search the
        translations based on key words or filter them based on their translation state.
    </p>
    <div class="row">
        <div class="col-md-12">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Filter</h2>
                        <mat-card>
                            <div class="row main-card">
                                <div class="col-md-12">
                                    <div fxLayout="row" class="mb-2">
                                        <mat-form-field fxFlex="100">
                                        <label>Search</label>
                                            <input matInput [formControl]="myControl" class="textInput"> 
                                        </mat-form-field>
                                    </div>

                                    <!-- <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Host</label>
                                                    <input type="text" class="textInput" aria-label="Host" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div> -->
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row mt-5 main-table">
        <div class="col-md-12">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="translation-header" fxLayout.xs="column"
                fxLayoutGap="1rem">
                <form class="w-100" [formGroup]="translateForm" (ngSubmit)="onTranslationSubmit()">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-tab-group>
                                <mat-tab *ngFor="let lang of languageList; let i=index;">
                                    <ng-template mat-tab-label>
                                        <div class="custom-label" (click)="getLanguageDataByCode(lang)">{{lang.name}}
                                        </div>
                                    </ng-template>
                                    <mat-card *ngFor="let langData of obs | async">
                                        <div class="row main-card">
                                            <div class="col-md-12">
                                                <div fxLayout="row">
                                                    <mat-form-field fxFlex="100">
                                                        <label>{{langData.key}}</label>
                                                        <input matInput [(ngModel)]="langData.value" class="textInput" [ngModelOptions]="{standalone: true}" />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]">
                                    </mat-paginator>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end">
                        <button type="submit" mat-raised-button class="btnsave submitbtn">
                            Save
                        </button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


<div class="footer-btn">
    <div class="row bottom-button">
        <div class="buttom-right">
            <button type="button" mat-raised-button class="btnsave backbtn" (click)="clearFilter($event)">
                Reset
            </button>
            <button mat-raised-button type="submit" class="btnsave submitbtn" (click)="filterTranslations()">
                Filter
            </button>
        </div>
    </div>
</div>