<div class="row">
    <div class="col-md-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
            fxLayoutGap="1rem">
            <div>
                <h2 class="table-subheader">Select parent</h2>
            </div>
        </div>

        <mat-dialog-content class="mat-typography">
            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <table mat-table [dataSource]="questionGroups" matSort class="mat-elevation-z1"
                            matSortActive="id" matSortDirection="asc">

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace"> # </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Question key </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.question}} </td>
                            </ng-container>

                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Type </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.type | titlecase }} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="headletterspace"> Actions</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <button type="button" mat-raised-button class="btnsave submitbtn"
                                        (click)="selectQuestionByID(element.id)">
                                        <span>Select</span>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="assessmentrow">
                            </tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button type="button" mat-raised-button class="btnsave backbtn" mat-dialog-close>Close</button>
        </mat-dialog-actions>
    </div>
</div>