<div class="row">
    <div class="col-md-12">
        <form [formGroup]="optionForm" (ngSubmit)="onSubmit()">
            <div class="row mb-4">
                <div class="col-md-12">
                    <h2 class="sub-header">Add answer</h2>
                    <mat-card>
                        <div class="row main-card">
                            <div class="col-md-12">
                                <div fxLayout="row">
                                    <mat-form-field fxFlex="100" style="height: 130px !important;">
                                        <label>Answer key</label>
                                        <textarea matInput formControlName="text"
                                        class="textInput"></textarea>
                                        <mat-hint>
                                            You can add translations by using this format: translation_key; country_code:Translation; other_country_code:Other
                                            translation; ex.: traveller_name; eng:Traveler's name; ger:Reisende Name;
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div div fxLayout="row" fxLayoutAlign="end center">
                <button type="button" mat-raised-button class="btnsave backbtn" mat-dialog-close>
                    Close
                </button>
                <button mat-raised-button class="btnsave submitbtn">
                    Submit
                </button>
            </div>
        </form>
    </div>
</div>