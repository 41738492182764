import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionsRoutingModule } from './questions-routing.module';
import { QuestionsComponent } from './components/questions/questions.component';
import { QuestionGroupComponent } from './components/question-group/question-group.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateQuestionComponent } from './components/create-question/create-question.component';
import { EditQuestionComponent } from './components/edit-question/edit-question.component';
import { CreateGroupComponent } from './components/create-group/create-group.component';
import { EditGroupComponent } from './components/edit-group/edit-group.component';
import { TranslateQuestionComponent } from './components/translate-question/translate-question.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ParentQuestionComponent } from './components/parent-question/parent-question.component';
import { AddOptionComponent } from './components/edit-question/add-option/add-option.component';
import { EditOptionComponent } from './components/edit-question/edit-option/edit-option.component';
import { SelectCountriesComponent } from './components/edit-question/select-countries/select-countries.component';
import { AddQuestionCountriesComponent } from './components/edit-question/add-question-countries/add-question-countries.component';
import { RemoveQuestionCountriesComponent } from './components/edit-question/remove-question-countries/remove-question-countries.component';
import { UpdateParentComponent } from './components/edit-question/update-parent/update-parent.component';
import { A1HomeComponent } from './components/edit-question/a1/a1-home/a1-home.component';
import { PwdHostComponent } from './components/edit-question/pwd/pwd-host/pwd-host.component';
import { TranslateQuestionGroupComponent } from './components/translate-question-group/translate-question-group.component';
import { TranslateQuestionOptionComponent } from './components/translate-question-option/translate-question-option.component';


@NgModule({
  declarations: [
    QuestionsComponent,
    QuestionGroupComponent,
    CreateQuestionComponent,
    EditQuestionComponent,
    CreateGroupComponent,
    EditGroupComponent,
    TranslateQuestionComponent,
    ParentQuestionComponent,
    AddOptionComponent,
    EditOptionComponent,
    SelectCountriesComponent,
    AddQuestionCountriesComponent,
    RemoveQuestionCountriesComponent,
    UpdateParentComponent,
    A1HomeComponent,
    PwdHostComponent,
    TranslateQuestionGroupComponent,
    TranslateQuestionOptionComponent
  ],
  imports: [
    CommonModule,
    AngularEditorModule,
    QuestionsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class QuestionsModule { }
