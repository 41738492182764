<div class="row">
    <div class="col-md-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
            fxLayoutGap="1rem">
            <div>
                <h2 mat-dialog-title class="table-subheader">Activity Log</h2>
            </div>
        </div>
        <div mat-dialog-content class="mat-typography">
            <div *ngIf="questionLogData.length !== 0; else noActivityLog">
                <div class="row pt-4">
                    <div class="col-md-12">
                        <mat-card class="main-card" *ngFor="let log of questionLogData">
                            <mat-card-header>
                                <mat-card-title>{{log.action_Date_To_Display}}, {{log.action_By}}</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <p class="m-4">{{log.entity}}: {{log.action}} {{log.attribute}} {{log.previous_value}} {{log.updated_value}}</p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
            <ng-template #noActivityLog>
                <p class="text-center p-3">No activity log found</p>
            </ng-template>
        </div>
        <div mat-dialog-actions align="end">
            <button type="button" mat-raised-button class="btnsave backbtn" mat-dialog-close>Close</button>
        </div>
    </div>
</div>