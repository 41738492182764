import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from '../../core/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  logoutUrl: string;
  homeUrl: string;
  loadhtml: boolean = false;
  message: any = '';

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  getUrls() {
    let url = `${this.env.API_URL}/appsettings`;
    this.http.get(url).subscribe((res: any) => {
      console.log('Share service response', res);
      if (res && res.portalLogOutURL && res.portalHomeURL) {
        this.logoutUrl = res.portalLogOutURL;
        this.homeUrl = res.portalHomeURL;
        this.loadhtml = true;
      }
    });
  }

}
