import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionService } from 'src/app/modules/questions/services/question.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {

  questionLogData: any;
  show_modified_questions_from_base: boolean = false;

  constructor(
    private questionService: QuestionService,
    private dialogRef: MatDialogRef<ActivityLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {

    this.questionLogData = [];

    this.questionService.getActivityLog(this.data, this.show_modified_questions_from_base).subscribe((res) => {
          
      res.data.forEach((log) => {
        this.questionLogData.push({
          action: log.action,
          action_By: log.action_By,
          action_Date: log.action_Date,
          action_Date_To_Display: log.action_Date_To_Display,
          attribute: log.attribute,
          entity: log.entity,
          previous_Value: log.previous_Value,
          subEntity: log.subEntity,
          updated_Value: log.subEntity
        });
      });
    });
    console.log('Activity log', this.questionLogData);
  }

}
