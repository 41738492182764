import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionOptionService {

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  // question option

  getCountriesByHost(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Countries?CountryType=host`);
  }

  getOptionByQuestionId(questionID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Option`);
  }

  addQuestionOption(questionID: number, data: any): Observable<any> {
    return this.http.post<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Option`, data)
  }

  getOptionByOptionId(optionID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}`);
  }

  updateOptionByOptionId(optionID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}`, data);
  }

  removeQuestionOption(optionID: number): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}`);
  }

  // Translate

  getOptionIdByTransalte(optionID: number, languageCode: string): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}/translation/${languageCode}`);
  }

  updateOptionIdByTransalte(optionID: number, languageCode: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}/translation/${languageCode}`, data);
  }

  // Order
  
  udpateOptionByQuestionIdByOrder(questionID: number, optionID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Option/${optionID}/Order`, data);
  }

  // Option parent

  getOptionParent(optionID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}/Parent`);
  }

  updateOptionParent(optionID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}/Parent`, data);
  }

  removeOptionParent(optionID: number, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*'),
      body: data
    }
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}/Parent`, options);
  }

  // Option country

  getOptionCountry(optionID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}/Country`);
  }

  updateOptionCountry(optionID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}/Country`, data);
  }

  removeOptionCountry(optionID: number, countryCode: string): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/Option/${optionID}/Country/${countryCode}`);
  }  

}
