<div class="row container mb-3">
  <div class="col-md-12">
    <h1 class="header">Create question</h1>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="questionForm" (ngSubmit)="onSubmit()">
      <div class="row group-params mb-4">
        <div class="col-md-12">
          <h2 class="sub-header">Question basic info</h2>
          <mat-card>
            <div class="row main-card">
              <div class="col-md-12">
                <!-- Group -->
                <div fxLayout="row">
                  <mat-form-field fxFlex="100">
                    <label>Group</label>
                    <input matInput formControlName="group" class="textInput" 
                      [matAutocomplete]="group" #trigger="matAutocompleteTrigger" />
                    <mat-icon (click)="
                        trigger.panelOpen
                          ? trigger.closePanel()
                          : trigger.openPanel()
                      " class="custom-icon custom-form-field-icon">
                      expand_more
                    </mat-icon>
                    <mat-autocomplete #group="matAutocomplete" [displayWith]="displayGroupName">
                      <mat-option *ngFor="let group of filteredOptions | async" [value]="group">
                        {{ group.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>
                      <span *ngIf="
                          !questionForm.get('group').valid &&
                          questionForm.get('group').touched
                        ">
                        Group is <strong>required</strong>
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Type -->
                <div fxLayout="row">
                  <mat-form-field fxFlex="100">
                    <label>Type</label>
                    <input type="text" formControlName="type" class="textInput" aria-label="Group" matInput
                      [matAutocomplete]="type" #trigger="matAutocompleteTrigger" />
                    <mat-icon (click)="
                        trigger.panelOpen
                          ? trigger.closePanel()
                          : trigger.openPanel()
                      " class="custom-icon custom-form-field-icon">
                      expand_more
                    </mat-icon>
                    <mat-autocomplete autoActiveFirstOption #type="matAutocomplete">
                      <mat-option *ngFor="let type of filteredType | async" [value]="type">
                        {{ type }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>
                      <span *ngIf="
                          !questionForm.get('type').valid &&
                          questionForm.get('type').touched
                        ">
                        Type is <strong>required</strong>
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Question key -->
                <div fxLayout="row">
                  <mat-form-field fxFlex="100" style="height: 100px !important;">
                    <label>Question key</label>
                    <input matInput formControlName="text" class="textInput"  [errorStateMatcher]="matcher" />
                    <mat-hint>
                      Only lower-case, underscores, no spaces
                    </mat-hint>
                    <!-- <mat-error>
                      <span *ngIf="
                          !questionForm.get('text').valid &&
                          questionForm.get('text').touched
                        ">
                        Question key is <strong>required</strong>
                      </span>
                    </mat-error> -->
                    <mat-error *ngIf="questionForm.get('text').hasError('required')">
                      <span>Question key is <strong>required</strong></span>
                    </mat-error>
                    <mat-error *ngIf="questionForm.get('text').hasError('pattern')">
                      <span>Question must be lower-case letters or numbers</span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Visibility -->
                <div fxLayout="row">
                  <mat-form-field fxFlex="100">
                    <label>Visibility</label>
                    <input type="text" formControlName="visibility" class="textInput" aria-label="Group" matInput
                      [matAutocomplete]="visibile" #trigger="matAutocompleteTrigger" />
                    <mat-icon (click)="
                        trigger.panelOpen
                          ? trigger.closePanel()
                          : trigger.openPanel()
                      " class="custom-icon custom-form-field-icon">
                      expand_more
                    </mat-icon>
                    <mat-autocomplete autoActiveFirstOption #visibile="matAutocomplete">
                      <mat-option *ngFor="let visibile of filteredVisibility | async" [value]="visibile">
                        {{ visibile }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>
                      <span *ngIf="
                          !questionForm.get('visibility').valid &&
                          questionForm.get('visibility').touched
                        ">
                        Visibility is <strong>required</strong>
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="footer-btn">
        <div class="row bottom-button">
          <div class="buttom-right">
            <button type="button" mat-raised-button class="btnsave backbtn" (click)="exitQuestion()">
              Cancel
            </button>
            <button mat-raised-button type="submit" class="btnsave submitbtn" [disabled]="questionForm.invalid">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>