import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from 'src/app/modules/localizations/services/localization.service';
import { QuestionGroupsService } from '../../services/question-groups.service';
import { QuestionOptionService } from '../../services/question-option.service';

@Component({
  selector: 'app-translate-question-option',
  templateUrl: './translate-question-option.component.html',
  styleUrls: ['./translate-question-option.component.scss']
})
export class TranslateQuestionOptionComponent implements OnInit {

  groupTranslateForm: FormGroup;
  translateName: any;
  languageList: any = [];
  languageCode: any = [];
  optionId: number;
  questionId: number;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private localizationService: LocalizationService,
    private questionOptionService: QuestionOptionService,
    private dialogRef: MatDialogRef<TranslateQuestionOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    console.log('Question option data', this.data);

    this.optionId = this.data[0];
    this.questionId = parseInt(this.data[1]);

    this.groupTranslateForm = this.fb.group({
      text: ['']
    });

    this.localizationService.getLanguages().subscribe((res: any) => {

      res.data.forEach((e) => {
        this.languageList.push({
          code: e.code,
          country: e.country,
          id: e.id,
          is_Default: e.is_Default,
          name: e.name
        });
      });

    });
  }

  tabchange(event) {
    console.log('Tabchange events', event);

    this.languageCode = [];
    this.languageList.forEach((e) => {
      if(e.name === event.tab.textLabel) {
        this.languageCode.push(e.code);
      }
    });

    console.log('Lanuage code', this.languageCode);
    if(this.languageCode.length !== 0) {
      this.getQuestionOptionTranslate(this.languageCode);
    }
  }

  getQuestionOptionTranslate(languageCode) {

    this.questionOptionService.getOptionIdByTransalte(this.optionId, languageCode).subscribe((res) => {
      console.log('getQuestionOptionTranslate data', res);

      this.groupTranslateForm.setValue({
        text: res.data.text
      });
    });
  }

  get translateFormData() {
    return this.groupTranslateForm.controls;
  }

  groupTranslateSubmit() {

    this.translateName = [];
    this.languageList = [];

    this.translateName.push({
      text: this.translateFormData.text.value
    });

    this.questionOptionService.updateOptionIdByTransalte(this.optionId, this.languageCode, this.translateName[0]).subscribe((res) => {
      console.log('Group translate data', res);
      this.openSnackbarSuccessMessage('Question option translate submitted successfully');
      this.ngOnInit();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
