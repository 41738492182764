<div class="row">
    <div class="col-md-12">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                fxLayoutGap="1rem">
                <div>
                    <h2 mat-dialog-title class="table-subheader">Activity Log</h2>
                </div>
            </div>
            <div mat-dialog-content class="mat-typography">
                <div *ngIf="questionLogData.length !== 0; else noActivityLog">
                    <div class="row pt-4">
                        <div class="col-md-12">
                            <mat-card class="main-card" *ngFor="let log of questionLogData">
                                <mat-card-header>
                                    <mat-card-title>{{log.action_Date_To_Display}}, {{log.action_By}}</mat-card-title>
                                   
                                    <mat-checkbox #checkbox="matCheckbox"  
                                        (click)="checkBoxChecked(checkbox, log)" class="mt-3 mb-3" fxFlex="100">Update</mat-checkbox>          
                                </mat-card-header>
                                <mat-divider></mat-divider>
                                <mat-card-content>
                                    <p class="m-4"><b>{{log.entity}}: </b> {{log.action}}</p>
                                    <p class="m-4"><b>Attribute Modified:</b> {{log.attribute}}</p>
                                    <p class="m-4"><b>Previous Value:</b> {{log.previous_Value}}</p>
                                    <p class="m-4"><b>Update Value:</b> {{log.updatedValue}}</p>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
                <ng-template #noActivityLog>
                    <p class="text-center p-3">No activity log found</p>
                </ng-template>
            </div>
            <div mat-dialog-actions align="end">
                <button type="button" mat-raised-button class="btnsave submitbtn" (click)="updateClient()">Update</button>
                <button type="button" mat-raised-button class="btnsave backbtn" mat-dialog-close>Close</button>
            </div>
    </div>
</div>