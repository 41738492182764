<div class="container">
    <h1 class="header">Question group edit</h1>
    <div class="row mb-5">
        <div class="col-md-12">
            <form [formGroup]="groupFrom" (ngSubmit)="onGroupSubmit()">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Group parameters</h2>
                        <mat-card>
                            <div class="row main-card">
                                <div class="col-md-12">
                                    <div fxLayout="row">
                                        <mat-form-field fxFlex="100">
                                            <label>Name</label>
                                            <input matInput formControlName="name" class="textInput"
                                                [errorStateMatcher]="matcher">
                                            <mat-error>
                                                <span
                                                    *ngIf="!groupFrom.get('name').valid && groupFrom.get('name').touched">Name
                                                    is <strong>required</strong></span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row">
                                        <mat-form-field fxFlex="100">
                                            <label>Group</label>
                                            <input type="text" formControlName="type" class="textInput"
                                                aria-label="Group" matInput [formControl]="myControl"
                                                [matAutocomplete]="auto" #trigger="matAutocompleteTrigger"
                                                [errorStateMatcher]="matcher">
                                            <mat-icon
                                                (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                class="custom-icon custom-form-field-icon">
                                                expand_more
                                            </mat-icon>
                                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                <mat-option *ngFor="let option of filteredOptions | async"
                                                    [value]="option">
                                                    {{option}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error>
                                                <span
                                                    *ngIf="!groupFrom.get('type').valid && groupFrom.get('type').touched">Group
                                                    is <strong>required</strong></span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end">
                                <button type="button" mat-raised-button class="btnsave backbtn" (click)="exitGroup()">
                                    Cancel
                                </button>
                                <button mat-raised-button class="btnsave submitbtn" [disabled]="!groupFrom.valid">
                                    Save
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Filter</h2>
                        <mat-card>
                            <div class="row filter-card">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Search</label>
                                                    <input matInput [formControl]="questionKeyFilter" class="textInput">
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Notifications</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                    [formControl]="registrationFilter" [matAutocomplete]="notify"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #notify="matAutocomplete">
                                                        <mat-option *ngFor="let option of registrationData"
                                                        [value]="option.name">
                                                        {{option.name}}
                                                    </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Country</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="countryFilter" [matAutocomplete]="country"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #country="matAutocomplete">
                                                        <mat-option *ngFor="let country of countriesData"
                                                            [value]="country.name">
                                                            {{country.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>State</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="visibilityFilter" [matAutocomplete]="statusflt">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #statusflt="matAutocomplete">
                                                        <mat-option *ngFor="let status of statusFilter"
                                                        [value]="status">
                                                        {{status}}
                                                    </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is profile</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="isProfileFilter" [matAutocomplete]="isProfile"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #isProfile="matAutocomplete">
                                                        <mat-option *ngFor="let filter of profileFilter | keyvalue"
                                                            [value]="filter.key">
                                                            {{filter.key}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is static</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="isStaticFilter" [matAutocomplete]="isStatic"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #isStatic="matAutocomplete">
                                                        <mat-option *ngFor="let option of staticFilter | keyvalue"
                                                        [value]="option.key">
                                                        {{option.key}}
                                                    </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is dcoument</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="isDocumentFilter" [matAutocomplete]="isDocument"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #isDocument="matAutocomplete">
                                                        <mat-option *ngFor="let option of documentFilter | keyvalue"
                                                        [value]="option.key">
                                                        {{option.key}}
                                                    </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is encrypted</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="isEncryptedFilter" [matAutocomplete]="encrypt"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #encrypt="matAutocomplete">
                                                        <mat-option *ngFor="let option of encryptedFilter | keyvalue"
                                                        [value]="option.key">
                                                        {{option.key}}
                                                    </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end">
                                <button type="button" mat-raised-button class="btnsave backbtn" (click)="clearFilter($event)">
                                    Reset
                                </button>
                                <button mat-raised-button type="submit" class="btnsave submitbtn" (click)="applyFilter()">
                                    Filter
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row main-table">
        <div class="col-md-12">
            <h2 class="sub-header">Order</h2>

            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <table mat-table [dataSource]="availableQuestions" matSort class="mat-elevation-z1">

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace"> # </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="question">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Question </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.question}} </td>
                            </ng-container>

                            <ng-container matColumnDef="order">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Order</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="!element.isEditable">
                                        {{element.order}}
                                    </div>
                                    <div *ngIf="element.isEditable">
                                        <mat-form-field style="width: 70px;">
                                            <input matInput type="text" [(ngModel)]="element.order" class="textInput">
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="headletterspace">Actions</span>
                                </th>

                                <td mat-cell *matCellDef="let element; let i = index">
                                    <button type="button" *ngIf="element.isEditable" mat-raised-button [disabled]="isReadOnly"
                                        class="btnsave submitbtn" (click)="saveQuestionOrder(element)">
                                        Save
                                    </button>
                                    <button type="button" *ngIf="!element.isEditable" mat-raised-button [disabled]="isReadOnly"
                                        class="btnsave backbtn" (click)="editQuestionOrder(element)">
                                        Edit
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="assessmentrow">
                            </tr>
                        </table>                        
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>

</div>