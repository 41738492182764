<div class="container">
    <h1 class="header">Countries</h1>
    <p class="p-text">
        This section contains a list of all the countries enabled in the PWD Assessment.
        If the country is disabled, the traveler will not be able to create a trip to such country.
    </p>
    <div class="row">
        <div class="col-md-12">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Filter</h2>
                        <mat-card>
                            <div class="row main-card">
                                <div class="col-md-12">
                                    <div fxLayout="row" class="mb-2">
                                        <mat-form-field fxFlex="100">                                           
                                            <label>Search</label>
                                            <input matInput [formControl]="myControl" class="textInput">                        
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Home</label>
                                                    <input type="text" class="textInput" aria-label="Home" matInput
                                                        [formControl]="homeFilterControl" [matAutocomplete]="home"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #home="matAutocomplete">
                                                        <mat-option *ngFor="let home of homeFilter"
                                                            [value]="home">
                                                            {{home}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>A1</label>
                                                    <input type="text" class="textInput" aria-label="A1" matInput
                                                        [formControl]="a1FilterControl" [matAutocomplete]="a1">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #a1="matAutocomplete">
                                                        <mat-option *ngFor="let a1 of servicesFilter"
                                                            [value]="a1">
                                                            {{a1}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Host</label>
                                                    <input type="text" class="textInput" aria-label="Host" matInput
                                                        [formControl]="hostFilterControl" [matAutocomplete]="host"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #host="matAutocomplete">
                                                        <mat-option *ngFor="let host of hostFilter"
                                                            [value]="host">
                                                            {{host}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>PWD</label>
                                                    <input type="text" class="textInput" aria-label="PWD" matInput
                                                        [formControl]="pwdFilterControl" [matAutocomplete]="pwd"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #pwd="matAutocomplete">
                                                        <mat-option *ngFor="let pwd of servicesFilter"
                                                            [value]="pwd">
                                                            {{pwd}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row main-table">
        <div class="col-md-12">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                fxLayoutGap="1rem">
                <div>
                    <h2 class="table-subheader">Available Countries</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <table mat-table [dataSource]="allCountries" matSort class="mat-elevation-z1" matSortActive="id"
                            matSortDirection="asc">

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace"> # </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Name </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Code </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="headletterspace"> Actions</span>
                                </th>

                                <td mat-cell *matCellDef="let element" class="btn-group" mdbDropdown>
                                    <button type="button" class="action-buttons-edit" (click)="translateQuestion(element.id)">Translate</button>
                                    <button type="button" class="action-buttons-translate" (click)="homeStatusChange(element.code, element.is_Home, element)" [ngClass] = "{'action-buttons-green':element.is_Home === true, 'action-buttons-grey':element.is_Home === false}">Home</button>
                                    <button type="button" class="action-buttons-translate" (click)="hostStatusChange(element.code, element.is_Host, element)" [ngClass] = "{'action-buttons-green':element.is_Host === true, 'action-buttons-grey':element.is_Host === false}">Host</button>
                                    <button type="button" class="action-buttons-translate" (click)="a1StatusChange(element.code, element.is_A1, element)" [ngClass] = "{'action-buttons-green':element.is_A1 === true, 'action-buttons-grey':element.is_A1 === false}">A1</button>
                                    <button type="button" class="action-buttons-translate" (click)="pwdStatusChange(element.code, element.is_PWD, element)" [ngClass] = "{'action-buttons-green':element.is_PWD === true, 'action-buttons-grey':element.is_PWD === false}">PWD</button>
                                    
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="assessmentrow"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="footer-btn">
    <div class="row bottom-button">
        <div class="buttom-right">
            <button mat-raised-button class="btnsave backbtn" (click)="clearFilter($event)">
                Reset
            </button>
            <button mat-raised-button type="submit" class="btnsave submitbtn" (click)="filterCountry()" >
                Filter
            </button>
        </div>
    </div>
</div>