import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { environment } from 'src/environments/environment';

export interface FilteredCountry {
  id: number;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class QuestionCountryService {

  public subjectCountryData = new Subject<any[]>();

  constructor(private http: HttpClient, private env: EnvironmentService) {
  }

  getFilteredQuestionData(data: any) {
    this.subjectCountryData.next(data);
  }

  // Whitelist

  getQuestionCountryWhitelist(questionID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/WhiteList`);
  }

  updateQuestionCountryWhitelist(questionID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/WhiteList`, data);
  }

  removeQuestionCountryWhitelist(questionID: number, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*'),
      body: data
    }
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/WhiteList`, options);
  }

  // Blacklist

  getQuestionCountryBlacklist(questionID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/BlackList`);
  }

  updateQuestionCountryBlacklist(questionID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/BlackList`, data);
  }

  removeQuestionCountryBlacklist(questionID: any, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*'),
      body: data
    }
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/BlackList`, options);
  }

  /**
   * Al Service
   */

  getA1HomeCountries(questionID: number, registrationID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/A1/${registrationID}`);
  }

  updateA1HomeCountries(questionID: number, registrationID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/A1/${registrationID}`, data);
  }

  removeA1HomeCountries(questionID: any, registrationID: number, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*'),
      body: data
    }
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/A1/${registrationID}`, options);
  }

  getAllA1Countries(questionID: number, registrationID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/A1/all?registrationID=${registrationID}`);
  }

  updateAllA1Countries(questionID: number, registrationID: number): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/A1/home/all`, registrationID);
  }

  updateAllA1HostCountries(questionID: number, registrationID: number): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/A1/host/all`, registrationID);
  }

  /**
 * PWD Service
 */

  getPwdHostCountries(questionID: number, registrationID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/PWD/${registrationID}`);
  }

  updatePwdHostCountries(questionID: number, registrationID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/PWD/${registrationID}`, data);
  }

  removePwdHostCountries(questionID: number, registrationID: number, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*'),
      body: data
    }
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/PWD/${registrationID}`, options);
  }

  getAllPwdCountries(questionID: number, registrationID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/PWD/all?registrationID=${registrationID}`);
  }

  updateAllPwdCountries(questionID: number, registrationID: number): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/PWD/host/all`, registrationID);
  }

  updateAllPwdHomeCountries(questionID: number, registrationID: number): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/Country/PWD/home/all`, registrationID);
  }

}
