import { Injectable } from '@angular/core';

function _window(): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  API_URL: string;
  envUrl: string;

  constructor() {

    // environment specific
    let newEnv = '';
    if(window.location.hostname.includes('dev-us')) {
      newEnv = 'dev-us';
    } else if(window.location.hostname.includes('qas-us')) {
      newEnv = 'qas-us';
    } else if(window.location.hostname.includes('dev-eu')) {
      newEnv = 'dev-eu';
    } else if(window.location.hostname.includes('qas-eu')) {
      newEnv = 'qas-eu';
    }  else if(window.location.hostname.includes('stg-us')) {
      newEnv = 'stg-us';
    }  else if(window.location.hostname.includes('stg-eu')) {
      newEnv = 'stg-eu';
    }

    this.envUrl = 'https://' + newEnv + '.pta.deloitte.com';

    // api specific
    let envAPI = '';
    if(window.location.hostname.includes('dev-us')) {
      envAPI = 'dapi-us';
    } else if(window.location.hostname.includes('qas-us')) {
      envAPI = 'qapi-us';
    } else if(window.location.hostname.includes('dev-eu')) {
      envAPI = 'dapi-eu';
    } else if(window.location.hostname.includes('qas-eu')) {
      envAPI = 'qapi-eu';
    } else if(window.location.hostname.includes('stg-us')) {
      envAPI = 'sapi-us';
    } else if(window.location.hostname.includes('stg-eu')) {
      envAPI = 'sapi-eu';
    } else if(window.location.hostname.includes('localhost')) {
      envAPI = 'dapi-us';
    }
    this.API_URL = 'https://' + envAPI + '.pta.deloitte.com';
  }

}
