import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { QuestionGroupsService } from '../../services/question-groups.service';
import { QuestionService } from '../../services/question.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {

  filteredOptions: Observable<string[]>;
  options: string[] = ['purpose', 'detail'];
  myForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  constructor(private fb: FormBuilder, private questionService: QuestionService,
    private questionGroupService: QuestionGroupsService,
    private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {

    this.myForm = this.fb.group({
      'name': ['', Validators.required],
      'type': ['', Validators.required],
    });

    this.filteredOptions = this.myForm.get('type').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

  }

  private _filter(value: string): string[] {
    if (value !== null) {
      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  onSubmit() {
    try {
      this.questionGroupService.createQuestionGroup(this.myForm.value).subscribe((res) => {
        console.log('New group created', res);
        this.openSnackbarSuccessMessage('Group created successfully');
        this.myForm.reset();
        this.router.navigate(['/questions/question-group']);
      },
        (err) => { console.log(err); },
      );
    } catch (ex) { }
  }

  exitGroup() {
    this.router.navigate(['/questions/question-group']);
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
