import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  getEmployeeSSO(obj) {

    let url = `${this.env.API_URL}/api/v1/SSO/CreateEmployeeSSOInfo`;
    let data = {
      employeeId: obj.employeeId,
      organizationId: obj.organizationId
    }
    return this.http.post(url, data);
  }

  accesstoken() {
    let url = `${this.env.API_URL}/accesstoken`;
    return this.http.get(url);
  }

  refreshToken() {
    let url = `${this.env.API_URL}/api/v1/RefreshToken`;
    return this.http.post(url, "");
  }

}
