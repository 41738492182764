<div class="row">
    <div class="col-md-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
            fxLayoutGap="1rem">
            <div>
                <h2 class="table-subheader">Countries</h2>
            </div>
        </div>
        <form [formGroup]="countryForm">
            <!-- Filter -->
            <div class="filterInput">
                <div fxLayout="row" class="mb-2">
                    <mat-form-field fxFlex="100">
                        <label>Search</label>
                        <input matInput formControlName="search"
                        class="textInput" (keyup)="filterCountry()" />
                        <mat-icon matSuffix class="custom-icon ml-3 mr-3" (click)="clearFilter()">close</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <mat-dialog-content class="mat-typography">
                <div class="row">
                    <div class="col-md-12">
                        <div class="sub-table table-responsive">
                            <table mat-table [dataSource]="listCountries" matSort 
                                class="mat-elevation-z1" matSortActive="id" matSortDirection="asc">

                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span class="headletterspace"> # </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span class="headletterspace">Name </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="headletterspace"> Actions</span>
                                    </th>

                                    <td mat-cell *matCellDef="let element">
                                        <div>
                                            <button type="button" mat-raised-button class="btnsave submitbtn"
                                                (click)="addCountry(element.id)">
                                                <span>Add</span>
                                            </button>
                                        </div>
                                        <div>
                                            <button type="button" mat-raised-button class="btnremove submitbtn"
                                                (click)="removeCountry(element.id)">
                                                <span>Remove</span>
                                            </button>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="countriesColumn; sticky: true"></tr>
                                <tr mat-row *matRowDef="let element; columns: countriesColumn;" class="assessmentrow">
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
        </form>

        <mat-dialog-actions align="end">
            <button mat-raised-button class="btnsave backbtn" (click)="closeDialog()">Close</button>
        </mat-dialog-actions>
    </div>
</div>