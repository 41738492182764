import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ROLES } from 'src/app/shared/constants/constants';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../auth/auth.service';
import { OrgSelectComponent } from '../dialogs/org-select/org-select.component';
import { ConfigService } from './config.service';
import { EnvironmentService } from './environment.service';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public orgDialogRef: any;
  public showLoader: boolean = false;
  token: any = null;

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private router: Router,
    private dialog: MatDialog,
    private env: EnvironmentService,
    private shared: SharedService
  ) { }

  bootstrap() {

    if (window.location.href.includes(`${this.env.envUrl}`) 
      && (localStorage.getItem('userToken') 
      && localStorage.getItem('appToken') 
      && localStorage.getItem('user_details')))
    {
      let user_details = JSON.parse(localStorage.getItem('user_details'));
      this.config.initAppConfig(user_details);
    } else {
      localStorage.removeItem('userToken');
      localStorage.removeItem('appToken');
      localStorage.removeItem('user_details');
      localStorage.removeItem('orgs');
      localStorage.removeItem('org_check');
    }

    this.token = this.getParamValueQueryString('UserAccessToken');
    if (this.token) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('appToken');
      localStorage.removeItem('user_details');
      localStorage.removeItem('orgs');
      localStorage.removeItem('org_check');
      
      localStorage.setItem('userToken', this.token);
    }

    if (localStorage.getItem('userToken') &&
      localStorage.getItem('appToken') &&
      localStorage.getItem('user_details'))
    {
      let user_details = JSON.parse(localStorage.getItem('user_details'));
      this.config.initAppConfig(user_details);
    } else {
      this.showLoader = true;
      this.auth.accesstoken().subscribe((res: any) => {
        this.showLoader = false;
        console.log('Access token response', res);
        if (res) {
          if (res.userAccessToken && res.userAllowedOrgs) {
            console.log('test for res', res);
            localStorage.setItem('userToken', res.userAccessToken);

            if (res.userAllowedOrgs.length == 1) {
              setTimeout(() => {
                this.showLoader = true;
                this.auth.getEmployeeSSO(res.userAllowedOrgs[0]).subscribe((res: any) => {
                  this.showLoader = false;
                  if (res && res.appAccessToken && res.ssoResponse) {
                    localStorage.setItem('appToken', res.appAccessToken);
                    localStorage.setItem('user_details', JSON.stringify(res.ssoResponse));

                    setTimeout(() => {
                      this.config.initAppConfig(res.ssoResponse);
                    }, 100);
                  }
                  let role = JSON.parse(localStorage.getItem('user_details'));
                  console.log('role', role);
                  if (role.appRoleName == ROLES.DEA || role.appRoleName == ROLES.DPA || role.appRoleName == ROLES.DET) {
                    this.router.navigate(['questions/question-group']);
                  }
                });
              }, 100);
            } else if (res.userAllowedOrgs.length > 1) {
              localStorage.setItem('orgs', JSON.stringify(res.userAllowedOrgs));
              this.orgDialogRef = this.dialog.open(OrgSelectComponent, {
                disableClose: true,
                autoFocus: false,
                data: res.userAllowedOrgs
              }
              );
            }
          }
        } else {
          console.log('test for error');
          debugger;
        }
      });
    }
    this.shared.getUrls();
  }

  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue: any;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  getUserRole(){
    let u = JSON.parse(localStorage.getItem('user_details'));
    return u.appRoleName;
}

}
