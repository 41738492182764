import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllLanguages } from '../../models/allLanguages';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  displayedColumns: string[] = ['id', 'code', 'name', 'country', 'actions'];
  allLanguages: MatTableDataSource<AllLanguages[]> = null;
  tableGroupFrom: FormGroup;
  languageInitialData: any = [];
  disableLang: boolean;
  id = '';
  code = '';
  name = '';
  country = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private localizationService: LocalizationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {

    this.tableGroupFrom = this.fb.group({
      id: [''],
      code: [''],
      name: [''],
      country: [''],
    });

    this.getAllLanguages();
  }

  getAllLanguages() {
    try {
      this.localizationService.getLanguages().subscribe((res: any) => {
        console.log('get all languages', res.data);
        this.allLanguages = new MatTableDataSource<AllLanguages[]>(res.data);
        this.allLanguages.paginator = this.paginator;
        this.allLanguages.sort = this.sort;

        res.data.forEach((e) => {
          this.languageInitialData.push({
            id: e.id,
            name: e.id,
            code: e.id,
            country: e.country,
            is_Default: e.is_Default,
          });
        });
      });

    } catch (ex) {}
  }

  createLanguage() {
    this.router.navigate(['create-language'], { relativeTo: this.route });
    console.log('create langugae');
  }

  removeLanguage(langCode: string) {
    this.localizationService.removeLanguage(langCode).subscribe((res) => {
      this.openSnackbarSuccessMessage('Language removed successfully');
      this.getAllLanguages();
    });
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
