import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CountriesService } from '../../../countries/services/countries.service';
import { LocalizationService } from '../../services/localization.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-edit-language',
  templateUrl: './edit-language.component.html',
  styleUrls: ['./edit-language.component.scss'],
})
export class EditLanguageComponent implements OnInit {

  filteredLanuage: Observable<string[]>;
  filteredCountry: Observable<string[]>;
  countryFilterData: any = [];
  languageFilterData: any = [];
  
  editLanguageForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  newLanguageData: any = [];
  languageList: any = [];
  countriesList: any = [];
  id: number;

  constructor(
    private localizationService: LocalizationService,
    private countriesService: CountriesService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.getLanguageById(this.id);

    this.localizationService.getLanguageList().subscribe((res) => {
      console.log('Get langugae list', res);
      this.languageList = res.data;
      this.languageFilterData.push(this.languageList);

      this.filteredLanuage = this.editLanguageForm.get('language').valueChanges.pipe(
        startWith(''),
        map((term) => {
          return this.languageFilterData[0].filter((language: any) => language.name.toLowerCase().includes(term.toString().toLowerCase()))
        })
      );
    });

    this.countriesService.getAllCountries().subscribe((res) => {
      console.log('Get countries list', res);
      this.countriesList = res.data;
      this.countryFilterData.push(res.data);

      this.filteredCountry = this.editLanguageForm.get('country').valueChanges.pipe(
        startWith(''),
        map((term) => {
          return this.countryFilterData[0].filter((country: any) => country.name.toLowerCase().includes(term.toString().toLowerCase()))
        })
      );
    });

    this.editLanguageForm = this.fb.group({
      language: ['', Validators.required],
      country: ['', Validators.required],
    });
  }

  getLanguageById(id: any) {
    try {
      this.localizationService.getLanguages().subscribe((res: any) => {
        console.log('get all languages', res.data);
        res.data.forEach((e) => {
          if (e.id === Number(id)) {
            this.editLanguageForm.setValue({
              language: e,
              country: e.country,
            });
          }
        });
      });

    } catch (ex) {}
  }

  displayFn(country: any) {
    return country ? country.name : '';
  }

  langDisplay(lang: any) {
    return lang
      ? [lang.name + ' - ' + lang.code + ' ' + (lang.directionality ? lang.directionality: '')]
      : '';
  }

  get langData() {
    return this.editLanguageForm.controls;
  }

  exitGroup() {
    this.router.navigate(['/languages']);
  }

  onSubmit() {

    this.newLanguageData = [];

    if(this.langData.language.value.languageListId !== undefined) {
      this.newLanguageData.push({
        languageListId: this.langData.language.value.languageListId,
        countryId: this.langData.country.value.id
      });
    } else {
      this.newLanguageData.push({
        languageListId: this.langData.language.value.id,
        countryId: this.langData.country.value.id
      });
    }

    try {
      this.localizationService
        .updateLanguage(this.id, this.newLanguageData[0])
        .subscribe(
          (res) => {
            console.log(JSON.stringify(res));
            this.openSnackbarSuccessMessage('Language updated successfully');
            this.router.navigate(['/languages']);
          },
          (error) => {
            if(error.error.status === 400) {
              this.openSnackbarSuccessMessage('Language already exists. Choose another language');
            }
          }
        );
    } catch (ex) {}
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
