import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionService } from 'src/app/modules/questions/services/question.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-basemodifiedquestions',
  templateUrl: './basemodifiedquestions.component.html',
  styleUrls: ['./basemodifiedquestions.component.scss']
})
export class BaseModifiedQuestionsComponent implements OnInit {

  questionLogData: any;
  show_modified_questions_from_base: boolean = true;
  newQuestionData: any = [];
  

  constructor(
    private questionService: QuestionService,
    private dialogRef: MatDialogRef<BaseModifiedQuestionsComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {

    this.questionLogData = [];
    this.questionService.getModifiedQuestionsByBase(this.data, this.show_modified_questions_from_base).subscribe((res) => {
          
      res.data.forEach((log) => {
        this.questionLogData.push({
          action: log.action,
          action_By: log.action_By,
          action_Date: log.action_Date,
          action_Date_To_Display: log.action_Date_To_Display,
          attribute: log.attribute,
          entity: log.entity,
          previous_Value: log.previous_Value,
          subEntity: log.subEntity,
          updated_Value: log.updated_Value ,
          updatedValue: log.updated_Value.updatedValue         
        });
      });
    });
    console.log('Activity log', this.questionLogData);
  }

  updateClient()
  {
    this.questionService
    .updateQuestion(this.data, this.newQuestionData)
    .subscribe(
      (res: any) => {
        this.openSnackbarSuccessMessage('Question updated successfully');
       // this.router.navigate(['/questions/questions/edit-question', this.questionID]);
    });
  }

  checkBoxChecked(checkbox: MatCheckbox, log: any ) { 
    this.newQuestionData.push({questionId: this.data, modifiedDetails: log});   
  }

  onUpdateQuestionSubmit()
  {
    console.log("Modified question");
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
