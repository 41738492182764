import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountriesRoutingModule } from './countries-routing.module';
import { CountriesComponent } from './components/countries/countries.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { TranslateCountriesComponent } from './components/translate-countries/translate-countries.component';


@NgModule({
  declarations: [
    CountriesComponent,
    TranslateCountriesComponent
  ],
  imports: [
    CommonModule,
    CountriesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatTableModule
  ]
})
export class CountriesModule { }
