<div class="row container mb-3">
    <div class="col-md-12">
        <h1 class="header">Edit Answer - {{answerKey}}</h1>
    </div>
</div>

<div class="row">
<div class="col-md-12">
    <div class="row group-params mb-5">
        <div class="col-md-12">

            <!-- Add option -->
            <div class="row mb-5">
                <div class="col-md-12">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                    fxLayoutGap="1rem">
                        <div>
                            <h2 class="table-subheader">Show For Countries</h2>
                        </div>  
                        <div>
                        <div style="padding:10px;cursor:pointer;" (click)="selectCountries()">
                            <img src="assets/images/add-icon.svg" alt="Add Icon" length="25" width="25" class="ml-1">
                            <span class="btn-text pl-2">Set Countries</span>
                        </div>
                        </div>
                        </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="sub-table table-responsive">
                                        <table mat-table [dataSource]="countriesData" matSort class="mat-elevation-z0"
                                            matSortActive="id" matSortDirection="asc">
                                            <ng-container matColumnDef="id">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="headletterspace"> ID </span>
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.id }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="name">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="headletterspace">Name</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.name }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="code">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="headletterspace">Code</span>
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{ element.code }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="actions">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="headletterspace"> Actions</span>
                                                </th>

                                                <td mat-cell *matCellDef="let element">
                                                    <button type="button" mat-raised-button class="btnremove submitbtn"
                                                        (click)="removeCountry(element.code)">
                                                        <span>Delete</span>
                                                    </button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="countriesColumn; sticky: true"></tr>
                                            <tr mat-row *matRowDef="let row; columns: countriesColumn"
                                                class="assessmentrow"></tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                </div>
            </div>

            <!-- Parent answers -->
            <div *ngIf="questionData.parent !== null">
                <div class="row mb-5">
                    <div class="col-md-12">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                            fxLayoutGap="1rem">
                        <div>
                            <h2 class="table-subheader">Parent Answers</h2>
                        </div>  
                        <div>
                        <div style="padding:10px;cursor:pointer;" (click)="updateParent()">
                            <img src="assets/images/add-icon.svg" alt="Add Icon" length="25" width="25" class="ml-1">
                            <span class="btn-text pl-2">Update</span>
                        </div>
                        </div>
                        </div>
                        <div *ngIf="parentQuestionData.length !== 0; else noparent">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="sub-table table-responsive">
                                                <table mat-table [dataSource]="parentQuestionData" matSort
                                                    class="mat-elevation-z0" matSortActive="id" matSortDirection="asc">
                                                    <ng-container matColumnDef="id">
                                                        <th mat-header-cell *matHeaderCellDef>
                                                            <span class="headletterspace"> ID </span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{ element.id }}
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="name">
                                                        <th mat-header-cell *matHeaderCellDef>
                                                            <span class="headletterspace">Name</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{ element.text }}
                                                        </td>
                                                    </ng-container>

                                                    <tr mat-header-row *matHeaderRowDef="parentColumn; sticky: true"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: parentColumn"
                                                        class="assessmentrow"></tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                        <ng-template>
                            <p class="text-center p-4">No parent answers</p>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<!-- Footer content -->
<div class="footer-btn">
    <div class="row bottom-button">
        <div class="buttom-right">
            <button type="button" mat-raised-button class="btnsave backbtn mr-4" (click)="closeEditOption()">
                Cancel
            </button>
        </div>
    </div>
</div>