import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilService } from 'src/app/core/services/util.service';
import { IdleTimeoutComponent } from '../components/idle-timeout/idle-timeout.component';
import { IDLE_WARNING_SECONDS, IDLE_TIMEOUT_SECONDS } from '../constants/constants';
import { SharedService } from './shared.service';

const FALLBACK_REFRESH_TOKEN_MINUTES = 30 * 60;

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {

  idleState: string;
  timedOut: boolean = false;
  timerRef: any;
  dialogRef: any;

  constructor(
    private idle: Idle,
    private auth: AuthService,
    public dialog: MatDialog,
    private shared: SharedService,
    private util: UtilService
  ) { }

  watch() {

    // sets an idle timeout of 14 mins.
    console.log(`Session idle warning in ${IDLE_WARNING_SECONDS} seconds`);
    this.idle.setIdle(IDLE_WARNING_SECONDS);
    // sets a timeout period of 60 seconds. after 14mins of inactivity, the user will be considered timed out.
    this.idle.setTimeout(IDLE_TIMEOUT_SECONDS);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      if (this.idleState == 'IDLE_WARNING') {
        this.idleState = 'NOT_IDLE';
        clearTimeout(this.timerRef);
        this.refreshSession();
      }
      this.idleState = 'NOT_IDLE';
      console.log(this.idleState);
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.timedOut = true;
      localStorage.clear();
      window.location.href = this.shared.logoutUrl;
    });
    this.idle.onIdleStart.subscribe(() => this.idleState = 'IDLE');
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'IDLE_WARNING';
      console.log(this.idleState);
      if (!this.dialogRef) {
        this.dialogRef = this.dialog.open(IdleTimeoutComponent);
      }
    });
    this.idle.watch();

    console.log(`Refresh session in ${this.getRefreshTime()} milli seconds`);
    this.timerRef = setTimeout(() => {
      this.refreshSession();
    }, this.getRefreshTime());

    // this.keepalive.interval(15);
    // this.keepalive.onPing.subscribe(() => console.log(new Date()));
  }

  refreshSession() {
    if (this.idleState != 'IDLE_WARNING') {
      this.auth.refreshToken().subscribe((res: any) => {
        console.log('Get refresh token response', res);
        if (res) {
          localStorage.setItem('userToken', res.userAccessToken);
          localStorage.setItem('appToken', res.appAccessToken);
        }
        console.log(`Refresh session in ${this.getRefreshTime()} milli seconds`);
        setTimeout(() => {
          this.timerRef = setTimeout(() => {
            this.refreshSession();
          }, this.getRefreshTime())
        }, 100)
      })
    }
  }

  reset() {
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
  }

  getRefreshTime() {
    return this.getExpirySeconds() * 1000;
  }

  getExpirySeconds() {
    let exp = parseInt(this.util.jwtDecode('exp')) - Math.trunc(Date.now() / 1000);
    console.log(`expiry seconds ${exp}`);
    return exp > 0 ? exp : FALLBACK_REFRESH_TOKEN_MINUTES
  }
}
