<div class="row container mb-3">
    <div class="col-md-12">
        <h1 class="header">Documents types</h1>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
            <div class="row group-params mb-4">
                <div class="col-md-12">
                    <h2 class="sub-header">Document type parameters</h2>
                    <mat-card>
                        <div class="row main-card">
                            <div class="col-md-12">

                                <!-- Key -->
                                <div fxLayout="row">
                                    <mat-form-field fxFlex="100">
                                        <label>Key</label>
                                        <input matInput formControlName="documentTypeText" class="textInput"
                                            [errorStateMatcher]="matcher">
                                        <mat-error>
                                            <span
                                                *ngIf="!myForm.get('documentTypeText').valid && myForm.get('documentTypeText').touched">Key
                                                is <strong>required</strong></span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- Name -->
                                <div fxLayout="row">
                                    <mat-form-field fxFlex="100">
                                        <label>Name</label>
                                        <input matInput formControlName="documentTypeName" class="textInput"
                                            [errorStateMatcher]="matcher">
                                        <mat-error>
                                            <span
                                                *ngIf="!myForm.get('documentTypeName').valid && myForm.get('documentTypeName').touched">Name
                                                is <strong>required</strong></span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- Service -->
                                <div fxLayout="row">
                                    <mat-form-field fxFlex="100">
                                        <label>Services</label>
                                        <mat-select formControlName="services" multiple>

                                            <mat-select-trigger>
                                                <mat-chip-list>
                                                    <mat-chip *ngFor="let service of services.value" [removable]="true"
                                                        (removed)="removeRegistraion(service)">
                                                        {{ service }}
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </mat-select-trigger>

                                            <mat-option *ngFor="let service of registrationData" [value]="service.name">
                                                {{service.name}}
                                            </mat-option>

                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <!-- Trips addition -->
                                <div fxLayout="row" class="mt-4">
                                    <label>Trips addition</label>
                                    <mat-radio-group formControlName="tripsAddition" aria-label="Select an option">
                                        <mat-radio-button value="manual" class="ml-4">Manual</mat-radio-button>
                                        <mat-radio-button value="auto">Auto</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="footer-btn">
                <div class="row bottom-button">
                    <div class="buttom-right">
                        <button type="button" mat-raised-button class="btnsave backbtn" (click)="exitGroup()">
                            Cancel
                        </button>
                        <button mat-raised-button class="btnsave submitbtn" [disabled]="myForm.invalid">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>