import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  jwtDecode(key) {
    let jwt: any = jwt_decode(localStorage.getItem('userToken'));
    return jwt[key] ? jwt[key] : '';
  }
}
