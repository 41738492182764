<div class="row container mb-3">
    <div class="col-md-12">
        <h1 class="header">Question group edit</h1>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
            <div class="row group-params mb-4">
                <div class="col-md-12">
                    <h2 class="sub-header">Group parameters</h2>
                    <mat-card>
                        <div class="row main-card">
                            <div class="col-md-12">
                                <div fxLayout="row">
                                    <mat-form-field fxFlex="100">
                                        <label>Name</label>
                                        <input matInput formControlName="name" class="textInput"
                                            [errorStateMatcher]="matcher">
                                        <mat-error>
                                            <span *ngIf="!myForm.get('name').valid && myForm.get('name').touched">Name
                                                is <strong>required</strong></span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <mat-form-field fxFlex="100">
                                        <label>Group</label>
                                        <input type="text" formControlName="type" class="textInput"
                                            matInput [matAutocomplete]="auto" #trigger="matAutocompleteTrigger"
                                            [errorStateMatcher]="matcher">
                                        <mat-icon
                                            (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                            class="custom-icon custom-form-field-icon">
                                            expand_more
                                        </mat-icon>
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error>
                                            <span
                                                *ngIf="!myForm.get('type').valid && myForm.get('type').touched">Group
                                                is <strong>required</strong></span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="footer-btn">
                <div class="row bottom-button">
                    <div class="buttom-right">
                        <button type="button" mat-raised-button class="btnsave backbtn" (click)="exitGroup()">
                            Cancel
                        </button>
                        <button mat-raised-button class="btnsave submitbtn" [disabled]="myForm.invalid">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
