import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CountriesService } from 'src/app/modules/countries/services/countries.service';
import { QuestionGroupsService } from '../../services/question-groups.service';
import { QuestionService } from '../../services/question.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {

  displayedColumns: string[] = ['id', 'question', 'order', 'actions'];
  availableQuestions: MatTableDataSource<any> = null;
  matcher = new MyErrorStateMatcher();

  groupFrom: FormGroup;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  availableQuestionsRowData: any = [];
  newavailableQuestionsRowData: any = [];
  options: string[] = ['purpose', 'detail'];
  id: number;
  name = '';
  type = '';

  countriesData: any = [];
  filteredQuestionData: any = [];
  filteredQuestionsArray : any = [];

  profileFilter = { 'is_Profile': true };
  staticFilter = { 'is_Static': true };
  encryptedFilter = { 'is_Encrypted': true };
  documentFilter = {'is_Document': true };
  notificationFilter = { 'A1': 'A1', 'PWD': 'PWD' };
  statusFilter = ['enabled', 'disabled'];
  allCountries: any = [];
  questionLogData: any = [];
  registrationData: any = [];

  questionKeyFilter = new FormControl('');
  isProfileFilter = new FormControl('');
  isStaticFilter = new FormControl('');
  isEncryptedFilter = new FormControl('');
  registrationFilter = new FormControl('');
  countryFilter = new FormControl('');
  visibilityFilter = new FormControl('');
  isDocumentFilter = new FormControl('');


  filterValues = {
    question: '',
    is_Profile: '',
    is_Static: '',
    is_Encrypted: '',
    is_Document: '',
    registations: '',
    country: '',
    visibility: ''
  };

  showSaveIcon: boolean = false;
  showEditIcon: boolean = true;

  VOForm: FormGroup;
  newOrderID: any = [];
  groupID: number;
  editedOrderID: any;
  newTableValue: any = [];
  isReadOnly: boolean;
  newClientId: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;


  constructor(private route: ActivatedRoute, private fb: FormBuilder,
    private questionService: QuestionService, private router: Router,
    private fbuilder: FormBuilder,
    private _formBuilder: FormBuilder,
    private questionGroupService: QuestionGroupsService,
    private countriesService: CountriesService,
    private snackBar: MatSnackBar) {
    this.VOForm = this.fbuilder.group({
      VORows: this._formBuilder.array([])
    });
  }

  ngOnInit() {

    this.isReadOnly = false;

    this.groupID = +this.route.snapshot.params['id'];

    // disable for clientid
    if (localStorage.getItem('user_details')) {
      this.newClientId = JSON.parse(localStorage.getItem('user_details'));
      if (this.newClientId.organizationId !== 0) {
        this.isReadOnly = true;
      }
    }

    this.getQuestionGroup(this.route.snapshot.params['id']);

    this.getQuestionOrder();

    this.getQuestionGroupByOrder();

    this.groupFrom = this.fb.group({
      'name': ['', Validators.required],
      'type': ['', Validators.required]
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    this.isProfileFilter.valueChanges.subscribe(
      (profile) => {
        console.log('profile', profile);
        if (profile !== null) {
          let profileType = Object.entries(this.profileFilter).filter((key) => key.includes(profile));

          this.filterValues.is_Profile = profileType.length > 0 && profileType[0][1] === true ? profileType[0][1].toString() : '';
          //this.availableQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.isStaticFilter.valueChanges.subscribe(
      (is_Static) => {
        if (is_Static !== null) {
          let staticType = Object.entries(this.staticFilter).filter((key) => key.includes(is_Static));
          this.filterValues.is_Static = staticType.length > 0 && staticType[0][1] === true ? staticType[0][1].toString() : '';
          //this.availableQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.isEncryptedFilter.valueChanges.subscribe(
      (encrypt) => {
        if (encrypt !== null) {
          let encryptType = Object.entries(this.encryptedFilter).filter((key) => key.includes(encrypt));
          this.filterValues.is_Encrypted = encryptType.length > 0 && encryptType[0][1] === true ? encryptType[0][1].toString() : '';
          //this.availableQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.questionKeyFilter.valueChanges
      .subscribe(
        question => {
          if (question !== null) {
            this.filterValues.question = question;
            //this.availableQuestions.filter = JSON.stringify(this.filterValues);
          }
        }
      );

    this.registrationFilter.valueChanges.subscribe(
      (service) => {
        if (service !== null) {
          this.filterValues.registations = service;
          //this.availableQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

       this.countryFilter.valueChanges
       .subscribe(
         country => {
           if (country !== null) {
             this.filterValues.country = country.toLowerCase();
             //this.availableQuestions.filter = JSON.stringify(this.filterValues);
           }
         }
       );

      this.visibilityFilter.valueChanges
      .subscribe(
        visibile => {
          if (visibile !== null) {
            this.filterValues.visibility = visibile.toLowerCase();
            //this.availableQuestions.filter = JSON.stringify(this.filterValues);
          }
        }
      );

      this.isDocumentFilter.valueChanges.subscribe(
        (isDocument) => {
          if (isDocument !== null) {
            let documentType = Object.entries(this.isDocumentFilter).filter((key) => key.includes(isDocument));
            this.filterValues.is_Document = isDocument.length > 0 && documentType[0][1] === true ? documentType[0][1].toString() : '';
            //this.availableQuestions.filter = JSON.stringify(this.filterValues);
          }
        });

      this.countriesService.getAllCountries().subscribe((res) => {
        console.log('get all countries', res.data);
        this.countriesData = res.data;
  
      });

      this.questionService.getRegistrations().subscribe((res) => {
        console.log('get all registrations', res.data)
        this.registrationData = res.data;
      });
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  filterType(value: string): string[] {
    if (value !== null) {
      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  getQuestionGroupByOrder() {

    this.questionService.getQuestionsByGroupId(this.groupID).subscribe((res) => {
      console.log('question group question mapped by order id', res.data);
      this.newavailableQuestionsRowData = res.data;

      this.newavailableQuestionsRowData.forEach((el) => {
        el['isEditable'] = false;
      });
      console.log('Get new availableQuestions row data', this.newavailableQuestionsRowData);
      this.filteredQuestionData = this.newavailableQuestionsRowData;
      this.availableQuestions = new MatTableDataSource(this.newavailableQuestionsRowData);
      this.availableQuestions.paginator = this.paginator;
      this.availableQuestions.sort = this.sort;
    });
  }

  getQuestionOrder() {
    try {
      this.questionService.getQuestions().subscribe((res: any) => {

        console.log('Get all question', res.data);
        res.data.forEach((e) => {
          if (e.group.id === parseInt(this.route.snapshot.params['id'])) {
            this.availableQuestionsRowData.push({
              id: e.id,
              question: e.question,
              order: e.order
            });
          }
        });
        console.log('availableQuestionsRowData', this.availableQuestionsRowData);
      });

    } catch (ex) { }
  }

  getQuestionGroup(id: any) {
    this.questionGroupService.getQuestionGroupsById(id).subscribe((res: any) => {
      this.id = res.data.id;
      this.groupFrom.setValue({
        'name': res.data.name,
        'type': res.data.type
      });
    });
  }

  getOrderValue(value: any) {
    this.editedOrderID = value;
  }

  editQuestionOrder(data) {
    data.isEditable = true;
  }

  saveQuestionOrder(data) {

    this.newOrderID = [];
    console.log('saveQuestionOrder data', data);

    this.newOrderID.push({
      order: Number(data.order)
    });
    this.questionService.updateQuestionByOrder(this.groupID, data.id, this.newOrderID[0]).subscribe((res) => {
      this.getQuestionGroupByOrder();
    });
    data.isEditable = false;
  }

  refresh(): void {
    window.location.reload();
  }

  onGroupSubmit() {
    this.questionGroupService.updateQuestionGroups(this.id, this.groupFrom.value)
      .subscribe((res: any) => {
        this.openSnackbarSuccessMessage('Group updated successfully');
        this.router.navigate(['/questions/question-group']); // TODO: Check which path needs to redirect
      }, (err: any) => {
        console.log(err);
      }
      );
  }

  exitGroup() {
    this.router.navigate(['/questions/question-group']); // TODO: Check which path needs to redirect
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  applyFilter(){
    this.filteredQuestionsArray = [];
    this.filteredQuestionData.filter((data: any, filter: string): boolean => {
      if (this.availableQuestions.paginator) {
        this.availableQuestions.paginator.firstPage();
      }
      let values = JSON.stringify(this.filterValues)
      let filterValue = JSON.parse(values);
      let conditions = true;

      for (let filterKey in filterValue) {
        if (filterKey === 'question' && filterValue[filterKey] != '') {
          conditions = conditions && data[filterKey].trim().toLowerCase().indexOf(filterValue[filterKey]) !== -1;
        }
        else if(filterKey === 'registations' && filterValue[filterKey] != ''){
          conditions = conditions && data.registations.filter((e) => { return (e.name === this.filterValues.registations)}).length > 0;
        }
        else if(filterKey === 'country' && filterValue[filterKey] != ''){
          conditions = conditions && data.questionCountry.filter((e) => { return (e.name.toLowerCase() === this.filterValues.country)}).length > 0;
        }
        else if((filterKey === 'is_Static' || filterKey === 'is_Profile' || filterKey === 'is_Encrypted' || filterKey === 'is_Documented') && filterValue[filterKey] != ''){
         let boolValue = filterValue[filterKey] != '' ? JSON.parse(filterValue[filterKey]): false;
         filterValue[filterKey] = boolValue;
         conditions = conditions && filterValue[filterKey] == data[filterKey];
        }
        else if (filterValue[filterKey].length) {
          conditions = conditions && filterValue[filterKey].includes(data[filterKey].trim().toLowerCase());
        }
      }

      if(conditions)
      {
        this.filteredQuestionsArray.push(data);
      }
      return conditions;
    });

      this.availableQuestions = new MatTableDataSource<any>(this.filteredQuestionsArray);
      this.availableQuestions.paginator = this.paginator;
      this.availableQuestions.sort = this.sort;
  }

  clearFilter(event) {
    this.questionKeyFilter.reset();
    this.isProfileFilter.reset();
    this.isStaticFilter.reset();
    this.isEncryptedFilter.reset();
    this.registrationFilter.reset();
     this.countryFilter.reset();
    this.visibilityFilter.reset();
    this.isDocumentFilter.reset();
    this.availableQuestions.filter = '';
    if (this.availableQuestions.paginator) {
      this.availableQuestions.paginator.firstPage();
    }
    this.filterValues = {
      question: '',
      is_Profile: '',
      is_Static: '',
      is_Encrypted: '',
      is_Document:'',
      registations: '',
      country: '',
      visibility: ''
    };
    this.availableQuestions = new MatTableDataSource(this.filteredQuestionData);
      this.availableQuestions.paginator = this.paginator;
      this.availableQuestions.sort = this.sort;
    event.stopPropagation();
  }

}
