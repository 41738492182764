import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { tap, catchError } from 'rxjs/operators';
import { AppService } from '../services/app.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private env: EnvironmentService,
    private router: Router,
    private app: AppService,
    private shared: SharedService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq: any;
    let headers = req.headers;
    let clientId: any;

    if (localStorage.getItem('user_details')) {
      clientId = JSON.parse(localStorage.getItem('user_details'));
      headers = headers.set('Accept-Client', `${clientId.organizationId}`);
    }

    if (localStorage.getItem('userToken')) {
      headers = headers.set('Authorization', `Bearer ${localStorage.getItem('userToken')}`);
    }
    if (localStorage.getItem('appToken')) {
      headers = headers.set('AppAuthorization', `Bearer ${localStorage.getItem('appToken')}`);
    }

    authReq = req.clone({
      headers: headers
    });

    return next.handle(authReq);

    // return next.handle(authReq).pipe(

    //   tap(evt => {
    //     if (evt instanceof HttpResponse) {
    //       if (evt.status == 302) {
    //         this.router.navigate(['page401']);
    //         localStorage.clear();
    //       }
    //       if (evt.status != 200) {
    //         this.router.navigate(['page401']);
    //         localStorage.clear();
    //       }
    //       return;
    //     }
    //   }),
    //   catchError((err: any) => {
    //     if (err instanceof HttpErrorResponse) {
    //       if (err.url.includes('appsettings') || err.url.includes('accesstoken')) {
    //         localStorage.clear();
    //         if (this.env.API_URL.includes('qas')) {
    //           window.location.href = 'https://qprt.deloitte.com/IASTCPortal/dko/main.aspx#LOGOUT';
    //         }
    //         else if (this.env.API_URL.includes('sapi')) {
    //           window.open("https://app4.iastax.com/IASTCPortal/dko/main.aspx?t=1625829818053#LOGIN");
    //         }
    //         else if (this.env.API_URL.includes('dev')) {
    //           window.open("https://app3.iastax.com/IASTCPortal/dko/main.aspx?t=1625829818053#LOGIN");
    //         } else {
    //           window.location.href = 'https://qprt.deloitte.com/IASTCPortal/dko/main.aspx#LOGOUT';
    //         }
    //       }

    //       if (err.status == 401 || err.status == 404 || err.status == 302) {
    //         if (err.status == 404) {
    //           this.shared.message = 'You are not authorized to access this Assessmemt'
    //           this.router.navigate(['page401']);
    //         } else {
    //           this.shared.message = 'You are not authorized to access this Application'
    //           this.router.navigate(['page401']);
    //         }
    //         this.app.showLoader = false;
    //       }
    //     }
    //     return of(err);
    //   })

    // );
  }
}
