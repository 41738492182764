import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from 'src/app/modules/localizations/services/localization.service';
import { QuestionGroupsService } from '../../services/question-groups.service';
import { TranslateQuestionGroupComponent } from '../translate-question-group/translate-question-group.component';
import { QuestionService } from '../../services/question.service';


@Component({
  selector: 'app-translate-question',
  templateUrl: './translate-question.component.html',
  styleUrls: ['./translate-question.component.scss']
})
export class TranslateQuestionComponent implements OnInit {

  questionTranslateForm: FormGroup;
  translateName: any;
  languageList: any = [];
  languageCode: any = [];

  isReadOnly: boolean;
  newClientId: any;

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = ['One', 'Two', 'Three'];
  config: AngularEditorConfig = {
    editable: true,
    height: '10rem',
    minHeight: '5rem',
    outline: false,
    defaultFontName: 'Open Sans',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  };


  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private localizationService: LocalizationService,
    private questionService: QuestionService,
    private dialogRef: MatDialogRef<TranslateQuestionGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    this.isReadOnly = false;

    this.questionTranslateForm = this.fb.group({
      text: [''],
      help: [''],
      placeholder: [''],
      validation_Help: ['']
    });

    // disable for clientid
    if (localStorage.getItem('user_details')) {
      this.newClientId = JSON.parse(localStorage.getItem('user_details'));
      if(this.newClientId.organizationId !== 0) {
        this.isReadOnly = true;
      }
    }

    this.localizationService.getLanguages().subscribe((res: any) => {

      res.data.forEach((e) => {
        this.languageList.push({
          code: e.code,
          country: e.country,
          id: e.id,
          is_Default: e.is_Default,
          name: e.name
        });
      });

    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  tabchange(event) {
    console.log('Tabchange events', event);

    this.languageCode = [];
    this.languageList.forEach((e) => {
      if (e.name === event.tab.textLabel) {
        this.languageCode.push(e.code);
      }
    });

    console.log('Lanuage code', this.languageCode);
    if (this.languageCode.length !== 0) {
      this.getQuestionTranslate(this.languageCode);
    }
  }

  getQuestionTranslate(languageCode) {

    this.questionService.getQuestionTranslateByLanguageCode(this.data, languageCode).subscribe((res) => {
      console.log('getQuestionTranslate data', res);

      this.questionTranslateForm.setValue({
        text: res.data.text,
        help: res.data.help,
        placeholder: res.data.placeholder,
        validation_Help: res.data.validation_Help
      });
    });
  }

  get translateFormData() {
    return this.questionTranslateForm.controls;
  }

  questionTranslateSubmit() {

    this.translateName = [];
    this.languageList = [];

    this.translateName.push({
      text: this.translateFormData.text.value,
      help: this.translateFormData.help.value,
      placeholder: this.translateFormData.placeholder.value,
      validation_Help: this.translateFormData.validation_Help.value
    });

    this.questionService.updateQuestionTranslateByLanguageCode(this.data, this.languageCode, this.translateName[0]).subscribe((res) => {
      console.log('Question translate data', res);
      this.openSnackbarSuccessMessage('Question translate submitted successfully');
      this.ngOnInit();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
