import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { QuestionService } from 'src/app/modules/questions/services/question.service';

export interface IMenu {
  text: string;
  routerLink?: string;
  children: IMenuItem[];
}
export interface IMenuItem {
  text: string;
  routerLink: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isExpanded = true;
  showSubmenu: boolean = false;
  showLangMenu: boolean = false;

  showSubSubMenu: boolean = false;
  showLogo: boolean = false;
  menuList: Observable<IMenu[]>;
  isShowing :boolean = false;
  getScreenWidth:any;
  getScreenHeight:any;
  showSideNav: boolean;
  sideNavContent: boolean;
  showQuestionLabel: boolean;

  @ViewChild('sidenav') sidenav: MatSidenav;
  mode = new FormControl('side' as MatDrawerMode);  

  constructor(private questionService: QuestionService) { }

  ngOnInit(): void {
    this.menuList = this.questionService.getList<IMenu>("assets/menu.json");
    this.onResize()
  }

  onResize(){
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.getScreenWidth <= 1024) {
      this.isShowing = false;
      this.showSideNav = true;
      this.showQuestionLabel = true;
      this.sideNavContent = false;
      this.mode = new FormControl('over' as MatDrawerMode);
    }
    else{
      this.showQuestionLabel = false;
      this.isShowing = true;
      this.showSideNav = false;
      this.sideNavContent = true;
      this.mode = new FormControl('side' as MatDrawerMode);
    }    
  }

  toggleSidenav() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
 }
 
 callMethods() {
     this.toggleSidenav();
 }

}
