import { NgModule } from '@angular/core';

import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentsTypesComponent } from './components/documents-types/documents-types.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateDocumentTypeComponent } from './components/create-document-type/create-document-type.component';
import { EditDocumentTypeComponent } from './components/edit-document-type/edit-document-type.component';
import { TranslateDocumentTypeComponent } from './components/translate-document-type/translate-document-type.component';


@NgModule({
  declarations: [
    DocumentsTypesComponent,
    CreateDocumentTypeComponent,
    EditDocumentTypeComponent,
    TranslateDocumentTypeComponent
  ],
  imports: [
    ReactiveFormsModule,
    SharedModule,
    DocumentsRoutingModule
  ]
})
export class DocumentsModule { }
