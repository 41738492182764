import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateLanguageComponent } from './components/create-language/create-language.component';
import { EditLanguageComponent } from './components/edit-language/edit-language.component';
import { LanguagesComponent } from './components/languages/languages.component';
import { TranslationsComponent } from './components/translations/translations.component';

const routes: Routes = [
  {
    path: 'languages', children: [
      { path: '', component: LanguagesComponent },
      { path: 'create-language', component: CreateLanguageComponent },
      { path: 'edit-language/:id', component: EditLanguageComponent }
    ]
  },
  {
    path: 'translations', children: [
      { path: '', component: TranslationsComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocalizationsRoutingModule { }
