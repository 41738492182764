import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { environment } from 'src/environments/environment';
import { QuestionGroups } from '../models/questionGroups';

@Injectable({
  providedIn: 'root'
})
export class QuestionGroupsService {

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  getQuestionGroups(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Groups?orderBy=Name&orderDir=ASC`);
  }

  getQuestionGroupsById(questionGroupID: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Groups/${questionGroupID}`);
  }

  createQuestionGroup(data: any): Observable<QuestionGroups> {
    return this.http.post<QuestionGroups>(`${this.env.API_URL}/api/v1/Questions/Groups`, data);
  }

  updateQuestionGroups(questionGroupID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/Groups/${questionGroupID}`, data)
  }

  removeQuestionGroupsById(questionGroupID: number): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/Groups/${questionGroupID}`);
  }

  // enable or disable

  enableQuestionGroupById(questionGroupID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/Groups/${questionGroupID}/enable`, data);
  }

  disableQuestionGroupById(questionGroupID: number): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/Groups/${questionGroupID}/enable`);
  }

  // translate

  getQuestionGroupTranslate(questionGroupID: number, languageCode: string): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Groups/${questionGroupID}/translation/${languageCode}`);
  }

  updateQuestionGroupTranslate(questionGroupID: number, languageCode: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/Groups/${questionGroupID}/translation/${languageCode}`, data);
  }

}
