import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionGroups } from '../../models/questionGroups';
import { QuestionService } from '../../services/question.service';

@Component({
  selector: 'app-parent-question',
  templateUrl: './parent-question.component.html',
  styleUrls: ['./parent-question.component.scss']
})
export class ParentQuestionComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'type', 'actions'];
  questionGroups: MatTableDataSource<QuestionGroups> = null;
  questionGroupInitialData: any = [];
  tableGroupFrom: FormGroup;
  questionTableGroups: any = [];
  newlyAddedData: any = [];
  newQuestionData: any = [];
  groupID: number;
  questionID: number;
  id = '';
  name = '';
  group = '';
  is_Enabled = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ParentQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    this.questionTableGroups = [];
    this.newlyAddedData = [];

    this.groupID = this.data.group.id;
    this.questionID = this.data.id;
    console.log('Parent question comp data', this.data);

    try {
      this.getAllQuestionGroup(this.groupID);
    } catch (ex) { }

  }

  getAllQuestionGroup(id) {
    this.questionService.getQuestionsByGroupId(id).subscribe((res: any) => {
      console.log('Get quetion data by group id', res.data);
      res.data.forEach((element, index)  => {
        if(element.id === this.questionID)
        {
            res.data.splice(index, 1);
        }
      });
      this.questionGroups = new MatTableDataSource<QuestionGroups>(res.data);
      this.questionGroups.paginator = this.paginator;
      this.questionGroups.sort = this.sort;
    });
  }

  selectQuestionByID(id: number) {
    this.newQuestionData.push({
      text: this.data.question,
      type: this.data.type,
      group: this.data.group.id,
      required: this.data.required,
      parent: id,
    });

    console.log('new question data', this.newQuestionData);
    this.questionService.updateParentQuestion(this.questionID, this.newQuestionData[0]).subscribe((res) => {
      console.log('selectQuestionByID data', res);
      this.openSnackbarSuccessMessage('Parent has been set successfully');
      this.dialogRef.close();
    }, (error) => {
      if(error.error === "Question parent has a circular dependency") {
        this.openSnackbarErrorMessage('Question parent has a circular dependency');
        return false;
      }
    });
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  openSnackbarErrorMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      panelClass: ['error-msg'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
