import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ROLES } from 'src/app/shared/constants/constants';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../auth/auth.service';
import { AppService } from '../../services/app.service';
import { ConfigService } from '../../services/config.service';

import { take } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  orgsList: any = [];
  orgName: string = '';
  showLogo: boolean = false;
  getScreenWidth:any;
  getScreenHeight:any;
  showQuestionLabel: boolean;

  constructor(
    private shared: SharedService, 
    private auth: AuthService,
    private app: AppService,
    private config: ConfigService,
    private router: Router
    ) { }
    

  ngOnInit(): void {
    this.onResize()
    
    setTimeout(() => {
      if (localStorage.getItem('orgs') && localStorage.getItem('user_details')) {
        let orgs = localStorage.getItem('orgs');
        let user = localStorage.getItem('user_details');
        if (user) {
          let userObj = JSON.parse(user);
          this.orgName = userObj.organizationName;
        }
        if (orgs) {
          this.orgsList = JSON.parse(orgs);
        }
      }
    }, 100);

    this.config.accessSubject.subscribe((res:any) => {
      let orgs = localStorage.getItem('orgs');
      let user = localStorage.getItem('user_details');
      if(user){
        let userObj = JSON.parse(user);
        this.orgName = userObj.organizationName
      }
      if(orgs){
        this.orgsList = JSON.parse(orgs);
      }
    });

  }

  selectOrg(org) {
    console.log(JSON.stringify(org));
    this.app.showLoader = true;
    setTimeout(() => {
      this.auth.getEmployeeSSO(org).subscribe((res: any) => {
        console.log(JSON.stringify(res));
        if (res && res.appAccessToken && res.ssoResponse) {
          localStorage.setItem('appToken', res.appAccessToken);
          localStorage.setItem('user_details', JSON.stringify(res.ssoResponse));

          setTimeout(() => {
            this.app.showLoader = false;
            this.config.accessSubject.pipe(take(1)).subscribe(res => {
              this.router.navigate(['questions/question-group']).then(() => {
                window.location.reload();
              });
            });
            this.config.initAppConfig(res.ssoResponse);
          }, 1000);
        } else {
          this.app.showLoader = false;
        }
      });
    }, 100);
  }

  home(event) {
    window.location.href = this.shared.homeUrl;
    event.stopPropagation();
  }

  logout() {
    console.log("Logged Out!!");
    localStorage.clear();
    window.location.href = this.shared.logoutUrl;
  }

  onResize(){
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.getScreenWidth <= 1024) {
      this.showQuestionLabel = false;
    }
    else
    {
      this.showQuestionLabel = true;
    }
  }

}
