<div class="row">
    <div class="col-md-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
            fxLayoutGap="1rem">
            <div>
                <h2 class="table-subheader">Translate</h2>
            </div>
        </div>
        <form [formGroup]="doctypeTranslateForm" (ngSubmit)="doctypeTranslateSubmit()">

            <mat-dialog-content class="mat-typography mt-3 mb-3">
                <div class="row">
                    <div class="col-md-12">
                        <mat-tab-group (selectedTabChange)="tabchange($event)">
                            <mat-tab *ngFor="let lang of languageList; let i=index;" [label]="lang.name">
                                <mat-card>
                                    <div class="row main-card">
                                        <div class="col-md-12">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Name</label>
                                                    <input formControlName="name" matInput class="textInput" />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </mat-dialog-content>

            <mat-dialog-actions align="end" class="mt-1 mb-1">
                <button type="button" mat-raised-button class="btnsave backbtn" (click)="closeDialog()">Close</button>
                <button mat-raised-button type="submit" class="btnsave submitbtn">
                    Submit
                </button>
            </mat-dialog-actions>
        </form>

    </div>
</div>