import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFound404Component } from './core/components/not-found404/not-found404.component';

const routes: Routes = [
  {
    path: 'questions',
    loadChildren: () => import('./modules/questions/questions-routing.module').then(m => m.QuestionsRoutingModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./modules/documents/documents-routing.module').then(m => m.DocumentsRoutingModule)
  },
  {
    path: 'localizations',
    loadChildren: () => import('./modules/localizations/localizations-routing.module').then(m => m.LocalizationsRoutingModule)
  },
  {
    path: 'countries',
    loadChildren: () => import('./modules/countries/countries-routing.module').then(m => m.CountriesRoutingModule)
  },
  {
    path: 'page401',
    component: NotFound404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
