import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDLE_TIMEOUT_SECONDS } from '../../constants/constants';

@Component({
  selector: 'app-idle-timeout',
  templateUrl: './idle-timeout.component.html',
  styleUrls: ['./idle-timeout.component.scss']
})
export class IdleTimeoutComponent implements OnInit, OnDestroy {

  seconds: number = IDLE_TIMEOUT_SECONDS;
  intervalRef: any;

  constructor(private dialogRef: MatDialog) { }

  ngOnInit() {
    this.intervalRef = setTimeout(() => {
      this.timer();
    }, 1000);
  }

  close() {
    this.dialogRef.closeAll();
  }

  timer() {
    if (this.seconds > 1) {
      this.seconds--;
      this.intervalRef = setTimeout(() => {
        this.timer();
      }, 1000);
    } else {
      this.dialogRef.closeAll();
      this.seconds = 5;
      clearTimeout(this.intervalRef);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.intervalRef);
  }

}
