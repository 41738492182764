import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { QuestionsModule } from './modules/questions/questions.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { LocalizationsModule } from './modules/localizations/localizations.module';
import { CountriesModule } from './modules/countries/countries.module';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { DocumentsModule } from './modules/documents/documents.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,
    QuestionsModule,
    DocumentsModule,
    LocalizationsModule,
    CountriesModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  exports: [
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
