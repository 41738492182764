import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from 'src/app/modules/localizations/services/localization.service';
import { TranslateQuestionGroupComponent } from 'src/app/modules/questions/components/translate-question-group/translate-question-group.component';
import { DocumentsService } from '../../services/documents.service';

@Component({
  selector: 'app-translate-document-type',
  templateUrl: './translate-document-type.component.html',
  styleUrls: ['./translate-document-type.component.scss']
})
export class TranslateDocumentTypeComponent implements OnInit {
  
  doctypeTranslateForm: FormGroup;
  translateName: any;
  languageList: any = [];
  languageCode: any = [];
  isReadOnly: boolean;
  newClientId: any;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private localizationService: LocalizationService,
    private documentsService: DocumentsService,
    private dialogRef: MatDialogRef<TranslateQuestionGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    this.languageList = [];
    this.isReadOnly = false;

    this.doctypeTranslateForm = this.fb.group({
      name: ['']
    });

    // disable for clientid
    if (localStorage.getItem('user_details')) {
      this.newClientId = JSON.parse(localStorage.getItem('user_details'));
      if(this.newClientId.organizationId !== 0) {
        this.isReadOnly = true;
      }
    }

    this.localizationService.getLanguages().subscribe((res: any) => {

      res.data.forEach((e) => {
        this.languageList.push({
          code: e.code,
          country: e.country,
          id: e.id,
          is_Default: e.is_Default,
          name: e.name
        });
      });

    });
  }

  tabchange(event) {
    console.log('Tabchange events', event);

    this.languageCode = [];
    this.languageList.forEach((e) => {
      if(e.name === event.tab.textLabel) {
        this.languageCode.push(e.code);
      }
    });
    
    if(this.languageCode.length !== 0) {
      this.getDoctypeTranslate(this.languageCode);
    }
  }

  getDoctypeTranslate(languageCode) {

    this.documentsService.getDocumentTypeTranslate(this.data, languageCode).subscribe((res) => {
      console.log('getQuestionGroupTranslate data', res);

      this.doctypeTranslateForm.setValue({
        name: res.data.name
      });
    });
  }

  get translateFormData() {
    return this.doctypeTranslateForm.controls;
  }

  doctypeTranslateSubmit() {

    this.translateName = [];
    this.languageList = [];

    this.translateName.push({
      name: this.translateFormData.name.value
    });

    console.log('Language code', this.languageCode);

    this.documentsService.udpateDocumentTypeTranslate(this.data, this.languageCode, this.translateName[0]).subscribe((res) => {
      console.log('Group translate data', res);
      this.openSnackbarSuccessMessage('Document type translate submitted successfully');
      this.ngOnInit();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
