import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocalizationsRoutingModule } from './localizations-routing.module';
import { LanguagesComponent } from './components/languages/languages.component';
import { CreateLanguageComponent } from './components/create-language/create-language.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditLanguageComponent } from './components/edit-language/edit-language.component';
import { TranslationsComponent } from './components/translations/translations.component';


@NgModule({
  declarations: [
    LanguagesComponent,
    CreateLanguageComponent,
    EditLanguageComponent,
    TranslationsComponent
  ],
  imports: [
    CommonModule,
    LocalizationsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class LocalizationsModule { }
