<div class="container">
    <h1 class="header">Groups</h1>
    <p class="p-text">
        This section allows structuring of questions into a logically interconnected clusters, e.g. company address
        related questions, home representative related questions etc. Each of the groups is displayed on a separate page
        in the traveller NIUMAD interface. The two major group types are “purpose”- for exemption engine to decide
        whether the PWD notification is required, and “detail” – questions dependent or PWD being required.
    </p>
    <div class="row main-table">
        <div class="col-md-12">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                fxLayoutGap="1rem">
                <div>
                    <h2 class="table-subheader">Available Groups</h2>
                </div>
                <div>
                    <div style="padding:10px;cursor:pointer;" (click)="createGroup()">
                        <img src="assets/images/add-icon.svg" alt="Add Icon" length="25" width="25" class="ml-1">
                        <span class="btn-text pl-2">Create group</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <table mat-table [dataSource]="questionGroups" matSort 
                         class="mat-elevation-z1" matSortActive="id" matSortDirection="asc">

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace"> # </span>
                                </th>
                                <td mat-cell *matCellDef="let question"> {{question.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Name </span>
                                </th>
                                <td mat-cell *matCellDef="let question"> {{question.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Type </span>
                                </th>
                                <td mat-cell *matCellDef="let question"> {{question.type}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Status </span>
                                </th>
                                <td mat-cell *matCellDef="let question" [ngClass] = "{'color-green':question.is_Enabled===true, 'color-red':question.is_Enabled===false}"> {{ question.is_Enabled === true ? 'Enabled' : 'Disabled' }} </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="headletterspace"> Actions</span>
                                </th>
                                <td mat-cell *matCellDef="let question" class="btn-group">
                                    <button type="button" class="action-buttons-edit" [routerLink]="['/questions/question-group/edit-group', question.id]">Edit</button>
                                    <button type="button" class="action-buttons-translate" (click)="translateQuestion(question.id)">Translate</button>
                                    <button type="button" class="action-buttons-visibility" (click)="statusChange(question.id, $event.target.innerHTML)">{{question.is_Enabled=== true ? 'Disable': 'Enable'}}</button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let question; columns: displayedColumns;" class="assessmentrow"></tr>
                       
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>                    
                </div>
            </div>
        </div>
    </div>
</div>