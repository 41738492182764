
export  const USACountryCode:string = 'US';

// in seconds 
export const MAX_SINGLE_SESSION: number = 2 * 60 * 60; // 2 hours
export const IDLE_WARNING_SECONDS: number = 1500 // 15 minutes;
export const IDLE_TIMEOUT_SECONDS: number = 120; // 2 minutes
export const REFRESH_TOKEN_BEFORE: number = 60; // 1 minute

export const ROLES = {
    DPA: 'DeloittePlatformAdmin',
    DEA: 'DeloitteEngagementAdmin',
    DET: 'DeloitteEngagementTeam'
}