import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateGroupComponent } from './components/create-group/create-group.component';
import { CreateQuestionComponent } from './components/create-question/create-question.component';
import { EditGroupComponent } from './components/edit-group/edit-group.component';
import { EditOptionComponent } from './components/edit-question/edit-option/edit-option.component';
import { EditQuestionComponent } from './components/edit-question/edit-question.component';
import { QuestionGroupComponent } from './components/question-group/question-group.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { TranslateQuestionGroupComponent } from './components/translate-question-group/translate-question-group.component';
import { TranslateQuestionComponent } from './components/translate-question/translate-question.component';

const routes: Routes = [
  {
    path: 'questions', children: [
      { path: '', component: QuestionsComponent },
      { path: 'create-question', component: CreateQuestionComponent },
      {
        path: 'edit-question/:id', children: [
          { path: '', component: EditQuestionComponent },
          { path: 'edit-option/:id', component: EditOptionComponent }
        ]
      },
      { path: 'translate-question', component: TranslateQuestionComponent },
    ]
  },
  {
    path: 'question-group', children: [
      { path: '', component: QuestionGroupComponent },
      { path: 'create-group', component: CreateGroupComponent },
      { path: 'edit-group/:id', component: EditGroupComponent },
      { path: 'translate-question-group', component: TranslateQuestionGroupComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuestionsRoutingModule { }
